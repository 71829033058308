import React, { useMemo, useCallback } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import Modal from 'components/Modal';

import { t } from 'localization';
import { useAppDispatch } from 'storeHooks';

import { useRightSideMenuContext } from 'modules/JGraph/view/RightSideMenu';
import { SortableReactionsContainer } from 'modules/JGraph/components/SortableBlocks';
import { AddBlockButton } from 'modules/JGraph/components/AddBlockButton';
import { getSortableReactions } from 'modules/JGraph/view/RightSideMenu/utils/common';
import { useBlockDelete } from 'modules/JGraph/view/RightSideMenu/settings/RandomSettings/hooks';
import { resortBlocksInIfAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { TagNames } from 'modules/JGraph/utils/types';

import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';

import { RenderConditionContext, useRenderConditionContext } from '../ConditionSettings';

const RandomSettings = function () {
  const { path, jBlockIndex } = useRightSideMenuContext();

  return (
    <div className='d-flex flex-column gap-16 mt-4' data-test-id='RandomSettings:Container'>
      <div className='pl-5 pr-5' style={{ color: 'var(--gray-600)' }}>
        {t(`RightSideMenu:random_block:description`)}
      </div>
      <div className='pl-5 pr-5 d-flex flex-column gap-16 mb-6' style={{ borderLeft: '1px solid var(--gray-200)' }}>
        <RenderConditionContext.Provider
          value={{
            prefix: ScreenBlockPath.joinIndexToPath(path, TagNames.random, jBlockIndex),
            globalIndex: undefined,
          }}
        >
          <RandomBlocks />
        </RenderConditionContext.Provider>
      </div>
    </div>
  );
};

const RandomBlocks = () => {
  const { getEditBlockTag, setAddingMenuType } = useRightSideMenuContext();
  const dispatch = useAppDispatch();
  const block = useMemo(() => getEditBlockTag(), [getEditBlockTag]);
  const { prefix: currentPrefix } = useRenderConditionContext();
  const { openDeleteIndex, setOpenDeleteIndex, setOpenDeleteHandler, deleteHandler } = useBlockDelete(currentPrefix);

  const sortableReactions = useMemo(() => getSortableReactions(block?.jblocks || []), [block?.jblocks]);
  const openAddingMenu = useCallback(() => {
    setTimeout(() => {
      setAddingMenuType('reactions', currentPrefix);
    }, 0);
  }, [currentPrefix, setAddingMenuType]);

  const onSortReactionsEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const oldGlobalIndex = sortableReactions[oldIndex]?.blockIndex;
        const newGlobalIndex = sortableReactions[newIndex]?.blockIndex;
        if (Number.isInteger(oldGlobalIndex) && Number.isInteger(newGlobalIndex)) {
          const newBlocks = arrayMove(block?.jblocks || [], oldGlobalIndex, newGlobalIndex);
          dispatch(resortBlocksInIfAsync({ newBlocks, contextPrefix: currentPrefix }));
        }
      }
    },
    [sortableReactions, block?.jblocks, dispatch, currentPrefix]
  );

  return (
    <>
      {sortableReactions.length > 0 && (
        <SortableReactionsContainer
          sortableBlocks={sortableReactions}
          setOpenDelete={setOpenDeleteHandler}
          useDragHandle
          axis='y'
          lockAxis='y'
          lockOffset={['0%', '100%']}
          onSortEnd={onSortReactionsEnd}
          lockToContainerEdges
          helperClass='draggingHelper'
        />
      )}
      <AddBlockButton onClick={openAddingMenu} text={t('ScreenBlocksList:AddVariant')} />
      <Modal
        isOpen={typeof openDeleteIndex === 'number'}
        title={t(`RightSideMenu:delete_block_title`)}
        buttonSubmitColor='danger'
        buttonSubmitText={t(`RightSideMenu:delete_block_submit`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        onCancelClick={() => setOpenDeleteIndex(undefined)}
        onActionClick={deleteHandler}
      >
        <p>{t(`RightSideMenu:delete_block_text`)}</p>
      </Modal>
    </>
  );
};

export default React.memo(RandomSettings);
