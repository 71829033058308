import React from 'react';
import { Button, Icon } from '@just-ai/just-ui';

import { t } from 'localization';

import { useDelayToggle } from 'utils/hooks/useDelayToggle';

import styles from './style.module.scss';

interface CopyItemsActionProps {
  copy: () => Promise<void>;
}
const CopyItemsAction = ({ copy }: CopyItemsActionProps) => {
  const [justCopied, triggerCopy] = useDelayToggle(false, 1000);

  return (
    <Button
      size='sm'
      className={styles.CopyItemsAction}
      data-copied={justCopied}
      color='primary'
      onClick={() => {
        copy()
          .then(triggerCopy)
          .catch(() => {});
      }}
      data-test-id='Table.Copy'
    >
      <Icon name={justCopied ? 'faCheck' : 'farCopy'} size='sm' className='mr-2' />{' '}
      {justCopied ? t('IntentItemsEdit:CopiedLabel') : t('IntentItemsEdit:CopyLabel')}
    </Button>
  );
};

CopyItemsAction.displayName = 'CopyItemsAction';

export default React.memo(CopyItemsAction);
