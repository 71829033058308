import React from 'react';

import { t } from 'localization';

import { ClipboardService } from 'services/ClipboardService';

import { KnowledgeBaseIntegration } from '../../domain/types';

import styles from './styles.module.scss';

const copyCode = (template: string, secret: string) => {
  const codeWithSecret = template.replaceAll('${secretName}', secret);
  const scriptContentLines = codeWithSecret.split('\n');
  const fourSpacesAsTab = '    ';
  const code = ['scriptEs6:', ...scriptContentLines].join('\n' + fourSpacesAsTab + fourSpacesAsTab + fourSpacesAsTab);
  void ClipboardService.copyToClipboard(code).catch(() => {});
};

interface CopyInvokeKnowledgeBaseCodeProps {
  base: KnowledgeBaseIntegration;
  template: string;
}
export const CopyInvokeKnowledgeBaseCode = React.memo(({ base, template }: CopyInvokeKnowledgeBaseCodeProps) => {
  return (
    <div className={styles.CopyInvokeKnowledgeBaseCode} onClick={() => copyCode(template, base.secretName)}>
      <div>{t('KhubIntegration:IntegrationList:CopyCode')}</div>
      <div className={styles.subTitle}>{t('KhubIntegration:IntegrationList:CopyCode:Description')}</div>
    </div>
  );
});

CopyInvokeKnowledgeBaseCode.displayName = 'CopyInvokeKnowledgeBaseCode';
