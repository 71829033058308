import React, { useState, useCallback, useMemo } from 'react';
import { InputText } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import { useAppSelector } from 'storeHooks';
import { t } from 'localization';

import { themeNameValidation } from 'modules/JGraph/utils/validators/themeName';
import { getAllStates } from 'reducers/JGraph.reducer/Graph';
import { JGraphTheme } from 'reducers/JGraph.reducer/types';
import { transformNameToThemePath } from 'modules/JGraph/utils/themesUtils';

interface RenameThemeModalInterface {
  isOpen: boolean;
  theme: JGraphTheme;
  onClose: () => void;
  onRename: (name: string) => void;
}
const RenameThemeModal = function ({ isOpen, onClose, theme, onRename }: RenameThemeModalInterface) {
  const { screens, themes } = useAppSelector(store => ({
    screens: store.JGraphReducer.graph.blocks,
    themes: store.JGraphReducer.graph.themes,
  }));

  const [name, setName] = useState(theme.value || '');
  const [isValid, setIsValid] = useState(false);
  const [errorText, setErrorText] = useState('');

  const allThemesNames = useMemo(
    () => themes.map(el => el.value).filter(el => el !== theme.value),
    [themes, theme.value]
  );
  const allStatesNames = useMemo(() => getAllStates(screens), [screens]);

  const onChange = useCallback(
    async (title: string) => {
      setName(title);

      const themeValue = transformNameToThemePath(title);
      const validationResult = await themeNameValidation(
        { name: themeValue },
        { existedStates: allStatesNames, existedThemes: allThemesNames }
      );

      setErrorText(validationResult.errors?.name?.message || '');
      setIsValid(validationResult.isValid);
    },
    [allStatesNames, allThemesNames]
  );

  const onSubmit = useCallback(() => onRename(transformNameToThemePath(name)), [name, onRename]);

  const inNothingChanged = useMemo(() => {
    return [name, transformNameToThemePath(name)].includes(theme?.value);
  }, [name, theme?.value]);

  return (
    <Modal
      isOpen={isOpen}
      title={t(`RenameThemeModal:Title`)}
      buttonSubmitColor='primary'
      buttonCancelColor='secondary'
      buttonSubmitText={t(`Save`)}
      buttonCancelText={t('Cancel')}
      buttonCancelOutline
      buttonSubmitDisabled={!isValid || inNothingChanged}
      onCancelClick={onClose}
      onActionClick={onSubmit}
      disableActionButtonAutoFocus
    >
      <div>
        <label className='form-control-label col-md-5 pl-0' htmlFor='themeName'>
          {t('RenameThemeModal:FieldName:Label')}
        </label>
        <InputText
          id='themeName'
          name='themeName'
          value={name}
          placeholder={theme?.value}
          errorText={errorText}
          onChange={onChange}
        />
      </div>
    </Modal>
  );
};

export default React.memo(RenameThemeModal);
