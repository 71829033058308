import React, { FC, useEffect, useMemo, useState } from 'react';
import { AudioPlayer, Button, IconButton, JustSelect, Textarea, OptionType, Banner } from '@just-ai/just-ui';
import { testTtsWidgetLocalization } from '../localization/testTtsWidget.loc';
import localize, { t } from 'localization';
import { CustomProviderOption, SpeechId } from '../types';
import { TextToSpeechProviderDetail } from '../api/client';
import '../style/test-tts-widget.scss';

import { useAppContext } from '../../Caila/components/AppContext';
import { getAvailableProviderOptions } from '../utils';
import { getDefaultTTSOptions } from 'views/Channels/TelephonyChannelsConfig/AsrAndTtsOptions/defaultOptions/getDefaultTTSOptions';

localize.addTranslations(testTtsWidgetLocalization);

const SSMLHint = () => (
  <Banner
    type='info'
    className='tts-widget__ssml_hint'
    BannerText={() => (
      <div>
        <span>{t('TestTtsWidget:SSMLHint') + ' '}</span>
        <a href={t('TestTtsWidget:LinkDocs')} target='_blank' rel='noopener noreferrer'>
          {t('TestTtsWidget:SSMLHint:TextInLink') + '.'}
        </a>
      </div>
    )}
  />
);

interface TtsWidgetFormProps {
  provider: TextToSpeechProviderDetail | SpeechId;
  voice: string;
  text: string;
  audio: string;
  fetching: boolean;
  isContainVariable: boolean;
  isSynthesizedTextEmpty: boolean;
  isSynthesisError: boolean;
  handleSynthesize: () => void;
  handleCloseWidget: () => void;
  setProvider: (provider: any) => void;
  setVoice: (voice: string) => void;
  setText: (text: string) => void;
  copyTextResult: () => void;
  onDownloadAudio: () => void;
  customProviderOptions: CustomProviderOption[];
  providers: TextToSpeechProviderDetail[];
}
export const TtsWidgetForm: FC<TtsWidgetFormProps> = React.memo(
  ({
    provider,
    voice,
    text,
    audio,
    handleSynthesize,
    handleCloseWidget,
    setProvider,
    setVoice,
    setText,
    fetching,
    copyTextResult,
    isContainVariable,
    isSynthesizedTextEmpty,
    isSynthesisError,
    onDownloadAudio,
    customProviderOptions,
    providers,
  }) => {
    const { TextToSpeechService, currentProject } = useAppContext();
    const [providerSelectOptions, setSelectOptions] = useState<OptionType[]>([]);
    const [availableProviderOptions, setAvailableProviderOptions] = useState<OptionType[]>([]);

    const TtsVoices = useMemo(() => {
      const innerProvider = providers.includes(provider as TextToSpeechProviderDetail)
        ? (provider as TextToSpeechProviderDetail)
        : customProviderOptions.find(customProvider => String(customProvider.id) === String(provider))?.providerName;

      if (!innerProvider) return [];
      return TextToSpeechService.getVoicesForProvider(innerProvider, currentProject?.language || 'en').map(voice => ({
        label: voice,
        value: voice,
      }));
    }, [TextToSpeechService, currentProject?.language, customProviderOptions, provider, providers]);

    useEffect(() => {
      if (!TtsVoices.map(ttsVoice => ttsVoice.value).includes(voice)) {
        const innerProvider = providers.includes(provider as TextToSpeechProviderDetail)
          ? (provider as TextToSpeechProviderDetail)
          : customProviderOptions.find(customProvider => String(customProvider.id) === String(provider))?.providerName;

        if (!innerProvider) return;
        const defaultVoice = getDefaultTTSOptions(
          currentProject?.language || 'EN',
          innerProvider,
          '',
          '',
          '',
          TextToSpeechService.ttsVoices
        );
        if (!defaultVoice) return;
        setVoice(String(defaultVoice?.voice));
      }
    }, [
      TextToSpeechService.ttsVoices,
      TtsVoices,
      currentProject?.language,
      customProviderOptions,
      provider,
      providers,
      setVoice,
      voice,
    ]);

    useEffect(() => {
      setAvailableProviderOptions(getAvailableProviderOptions(providers));
    }, [providers]);

    useEffect(() => {
      const customProviderSelectOptions = customProviderOptions.map(option => ({
        label: option.name,
        value: option.id,
      }));
      setSelectOptions([...availableProviderOptions, ...customProviderSelectOptions]);
    }, [customProviderOptions, availableProviderOptions, setSelectOptions]);

    return (
      <>
        <div className='tts-widget' id='TestTtsWidget'>
          <div className='tts-widget__block tts-widget__block_header tts-widget__block_mini-margin'>
            <h3>{t('TestTtsWidget: title')}</h3>

            <div className='tts-widget__close'>
              <IconButton onClick={handleCloseWidget} name='faTimes' color='secondary' size='sm' flat />
            </div>
          </div>

          <div className='tts-widget__block tts-widget__block_mini-margin'>
            <small className='tts-widget__title'>{t('TestTtsWidget: subtitle 1')}</small>

            <div className='tts-widget__provider'>
              <JustSelect
                options={providerSelectOptions}
                value={provider}
                onChange={option => option && setProvider(option[0])}
                className='tts-widget__provider_vendor'
              />
              <JustSelect
                options={TtsVoices}
                value={voice}
                onChange={option => option && setVoice(String(option[0]) || TtsVoices[0].value)}
                className='tts-widget__provider_vendor'
                inputPlaceholder={t('TestTtsWidget: placeholder')}
              />
            </div>
            <SSMLHint />
          </div>

          <div className='tts-widget__title tts-widget__title_text-synthesized'>
            <span>{t('TestTtsWidget: subtitle 2')}</span>
            <Button
              onClick={copyTextResult}
              className='tts-widget__btn tts-widget__btn_copy'
              iconRight='falCopy'
              color='primary'
              withoutPadding
              size='sm'
              flat
            >
              {t('TestTtsWidget: copy btn')}
            </Button>
          </div>
          <Textarea
            wrapperClassName='tts-widget__text-for-synthesis'
            value={text}
            rows={5}
            onChange={setText}
            placeholder={t('TestTtsWidget:Text:Placeholder')}
          />

          {isContainVariable && (
            <div className='tts-widget__banner'>
              <Banner
                type='warning'
                BannerText={() => <div className='tts-widget__banner-info'>{t('TestTtsWidget: warning variable')}</div>}
              />
            </div>
          )}

          <div className='tts-widget__block tts-widget__block_sample tts-widget__block_mini-margin'>
            <AudioPlayer
              url={audio}
              fetching={fetching}
              CustomButton={() => (
                <IconButton
                  onClick={onDownloadAudio}
                  disabled={!Boolean(audio)}
                  size='sm'
                  flat
                  name='farArrowToBottom'
                />
              )}
            />
          </div>

          <Button
            onClick={handleSynthesize}
            color='primary'
            className='tts-widget__btn tts-widget__btn_synthesis'
            size='sm'
            disabled={isSynthesizedTextEmpty || fetching}
          >
            {t('TestTtsWidget: synthesis btn')}
          </Button>

          {isSynthesisError && (
            <div className='tts-widget__block tts-widget__block_error'>{t('TestTtsWidget: synthesis error')}</div>
          )}
        </div>
      </>
    );
  }
);
