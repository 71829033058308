import React, { useCallback, useState } from 'react';
import { Spinner } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import { t } from 'localization';

import { useProjectGroupsContext } from '../../context';

const ProjectGroupLeaveModal = ({ userId }: { userId?: number }) => {
  const {
    isLeaveGroupModalOpen,
    setIsLeaveGroupModalOpen,
    setProjectGroupId,
    ProjectGroupsService,
    currentProjectGroupId,
  } = useProjectGroupsContext();
  const [fetching, setFetching] = useState(false);
  const leaveGroup = useCallback(async () => {
    setFetching(true);
    if (!userId) return;
    try {
      await ProjectGroupsService.deleteUserFromAccount(Number(currentProjectGroupId), userId);
      setProjectGroupId(null);
      setIsLeaveGroupModalOpen(false);
    } catch {
      setFetching(false);
    }
  }, [ProjectGroupsService, currentProjectGroupId, setProjectGroupId, setIsLeaveGroupModalOpen, userId]);

  return (
    <Modal
      isOpen={isLeaveGroupModalOpen}
      onCancelClick={() => setIsLeaveGroupModalOpen(false)}
      title={t('ProjectGroupLeaveModal:Title')}
      buttonCancelText={t('ProjectGroupLeaveModal:Button:Cancel')}
      buttonSubmitText={t('ProjectGroupLeaveModal:Button:Ok')}
      buttonSubmitColor='danger'
      onActionClick={leaveGroup}
      data-test-id='ProjectGroupLeaveModal'
    >
      {fetching && <Spinner size='2x' />}
      {t('ProjectGroupLeaveModal:Text')}
    </Modal>
  );
};

export default ProjectGroupLeaveModal;
