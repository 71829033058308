import { createContext, FunctionComponent, useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { LogLabelingState, LogLabelingDispatchAction, useGroupingReducer, LogLabelingMode } from './reducers';
import {
  LogLabelingSessionData,
  GroupingRequestData,
  PhrasesCleaningRequestData,
  PhraseStatusData,
  GroupingSummaryData,
  TypeEnum,
} from '@just-ai/api/dist/generated/Caila';
import { useLocation } from 'wouter';

import { useAppContext } from '../../components/AppContext';
import {
  useLoading,
  LoadCallback,
  getLogLabelingSessionUrl,
  useError,
  useModal,
  ModalControl,
  SetErrorCallback,
  ClearErrorCallback,
  Groupings,
  ErrorMessage,
  Groups,
  getDeletedPhrasesMessage,
  getProcessedPhrasesMessage,
} from '../../utils';
import { Spinner, usePrevious } from '@just-ai/just-ui';
import { GroupingsDataset, SOURCE_GROUPING_NAME } from '../../utils/groupings';
import { LogLabelingService } from './LogLabelingService';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { uniqueId } from 'lodash';

const GROUPINGS_UPDATE_TIMEOUT = 1000;

type LogLabelingContextType = {
  state: LogLabelingState;
  dispatch: LogLabelingDispatchAction;

  sessionId?: number;

  session?: LogLabelingSessionData;

  groupings: GroupingsDataset;
  selectedGroupingId?: string;
  selectedGrouping?: GroupingSummaryData;
  selectGrouping: (id: string) => void;
  deleteGrouping: (id: string) => void;
  startGrouping: (parameters: GroupingRequestData) => void;

  setTab: (value: string) => void;

  isLoading: boolean;
  isFileUploading: boolean;
  isDialogsUploading: boolean;
  load: LoadCallback;
  loadFile: LoadCallback;
  loadDialog: LoadCallback;

  setError: SetErrorCallback;
  clearError: ClearErrorCallback;
  errorMessage: ErrorMessage;

  createSession: (file: File) => Promise<unknown>;
  uploadFromDialog: () => Promise<unknown>;

  deletePhrases: (indexes: string[]) => void;
  clearPhrases: (indexes: string[]) => void;
  cleanPhrases: (requestData: PhrasesCleaningRequestData) => Promise<unknown>;
  stagePhrases: (indexes: string[], intentId: number) => void;
  moveStagedPhrases: (phrases: string[], toIntentId: number) => void;
  stageAllFilteredPhrases: () => void;
  applyChanges: () => void;

  uploadDialog: ModalControl;
  labelingDialog: ModalControl;
  selectIntentDialog: ModalControl;
  stagingApplyDialog: ModalControl;
  clearDataDialog: ModalControl;
  stageAllDialog: ModalControl;
  getStagingIntents: () => Promise<void>;
  updateStagingIntents: () => Promise<void>;
};

export const LogLabelingContext = createContext({} as LogLabelingContextType);

export const LogLabelingContextProvider: FunctionComponent<{
  id?: number;
  addAlert: (notification: AlertNotificationItemProps) => void;
}> = props => {
  const { accountId, projectShortName, language } = useAppContext();
  const { addAlert } = props;
  const logLabelingService = useMemo(
    () => new LogLabelingService(accountId, projectShortName),
    [accountId, projectShortName]
  );

  const groupingsUpdateTimeout = useRef<number | NodeJS.Timeout>();

  const [state, dispatch] = useGroupingReducer();

  const [session, setSession] = useState<LogLabelingSessionData>();
  const sessionId = session?.id;

  const [, setLocation] = useLocation();

  const uploadDialog = useModal();
  const { show: uploadDialogShow } = uploadDialog;
  const labelingDialog = useModal();
  const clearDataDialog = useModal();
  const selectIntentDialog = useModal();
  const stagingApplyDialog = useModal();
  const stageAllDialog = useModal();

  const [isLoading, load] = useLoading();
  const [isFileUploading, loadFile] = useLoading();
  const [isDialogsUploading, loadDialog] = useLoading();
  const [errorMessage, setError, clearError] = useError();

  const [groupings, setGroupings] = useState<GroupingsDataset>({});
  const [selectedGroupingId, setSelectedGroupingId] = useState<string>();
  const [selectedGrouping, setSelectedGrouping] = useState<GroupingSummaryData>();

  const loadSessionData = useCallback(
    async (sessionId: number, sessionType?: TypeEnum) => {
      if (typeof sessionId !== 'number') return;
      const fromDialog = sessionType !== TypeEnum.FileSession;
      try {
        const { allPhrases, statuses, groupings, stagingIntents } = await load(
          logLabelingService.getSessionData(sessionId)
        );
        const groupingsDataset = Groupings.toDataset(groupings, fromDialog);
        setGroupings(groupingsDataset);
        const stagedPhrases = stagingIntents
          .flatMap(intent => intent.stagedPhrases)
          .filter((phrase): phrase is string => typeof phrase === 'string')
          .map(phrase => ({ text: phrase, status: PhraseStatusData.S, id: uniqueId() }));
        //здесь мы загружаем все фразы из сессии в группировку source
        dispatch({
          type: 'SET_METHOD',
          method: 'source',
          allPhrases,
          statuses: statuses as PhraseStatusData[],
          stagedPhrases,
        });
        //если сессия создана из файла, то мы просто устанавливаем группировку со всеми фразами как выбранную группировку
        if (!fromDialog) {
          setSelectedGroupingId(SOURCE_GROUPING_NAME);
          return;
        }
        //если сессия создана из диалогов, то мы сначала смотрим есть ли распознанные среди загруженных фраз
        const recognizedGrouping = groupings.find(
          grouping => grouping.createdBySystem && grouping.name === 'Classification'
        );
        //и есть ли фразы вообще
        const unRecognizedGrouping = groupings.find(
          grouping => grouping.createdBySystem && grouping.name === 'NotRecognizedMessages'
        );
        //дальше в зависимости от наличия ставим ту или иную группировку
        if (recognizedGrouping?.groupsCount) {
          setSelectedGrouping(recognizedGrouping);
          setSelectedGroupingId(`grouping_${recognizedGrouping.groupingId}`);
          return;
        }
        if (unRecognizedGrouping?.groupsCount) {
          setSelectedGrouping(unRecognizedGrouping);
          setSelectedGroupingId(`grouping_${unRecognizedGrouping.groupingId}`);
          return;
        }
      } catch (error) {
        setError(error);
      }
    },
    [dispatch, load, logLabelingService, setError]
  );

  const loadLastSession = useCallback(
    () =>
      load(logLabelingService.getLastSession())
        .then(session => {
          if (session?.id) {
            setSession(session);
            loadSessionData(session.id, session.type);
          } else uploadDialogShow();
        })
        .catch(setError),
    [load, loadSessionData, logLabelingService, setError, uploadDialogShow]
  );

  useEffect(() => {
    if (typeof props.id === 'number') {
      load(logLabelingService.getSession(props.id))
        .then(session => {
          if (session && session.id) {
            setSession(session);
            loadSessionData(session.id, session.type);
          } else uploadDialogShow();
        })
        .catch(error => {
          loadLastSession();
          setError(error);
        });
    } else {
      loadLastSession();
    }
  }, [props.id, load, loadLastSession, loadSessionData, logLabelingService, setError, uploadDialogShow]);

  //setLocation on sessionId change
  useEffect(() => {
    setLocation(getLogLabelingSessionUrl(sessionId || props.id));
  }, [props.id, sessionId, setLocation]);

  //select grouping

  const prevSelectedGroupingId = usePrevious(selectedGroupingId);

  useEffect(() => {
    //если что то пошло не так, то выбранной группировки не будет
    if (!selectedGroupingId || typeof sessionId !== 'number' || state.mode === LogLabelingMode.STAGING)
      return setSelectedGrouping(undefined);
    //если выбрана группировка со всеми фразами (она есть только когда сессия создана из файла) то как таковой выбранной группировки не будет,
    //так как со всеми фразами - псевдогруппировка, в явном виде ее нет на бэке
    if (selectedGroupingId === SOURCE_GROUPING_NAME) {
      if (state.mode !== LogLabelingMode.SOURCE) dispatch({ type: 'SET_METHOD', method: 'source' });
      setSelectedGrouping(undefined);
      return;
    }
    const groupingToSelect = groupings[selectedGroupingId];
    setSelectedGrouping(groupingToSelect);
    if (!selectedGroupingId || !groupingToSelect?.id || selectedGroupingId === prevSelectedGroupingId) return;
    load(logLabelingService.getGroupingGroups(sessionId, groupingToSelect?.id))
      .then(allGroups => {
        dispatch({ type: 'SET_METHOD', method: groupingToSelect?.parameters?.method || 'source', allGroups });
        clearError();
      })
      .catch(setError);
  }, [
    clearError,
    dispatch,
    groupings,
    load,
    logLabelingService,
    prevSelectedGroupingId,
    selectedGroupingId,
    sessionId,
    setError,
    state.mode,
  ]);

  const updateGroupings = useCallback(() => {
    if (typeof sessionId !== 'number') return;
    logLabelingService
      .getGroupings(sessionId)
      .then(newGroupings => {
        const newGroupingsDs = Groupings.toDataset(newGroupings, session?.type !== TypeEnum.FileSession);
        if (JSON.stringify(newGroupingsDs) !== JSON.stringify(groupings)) {
          setGroupings(Groupings.toDataset(newGroupings, session?.type !== TypeEnum.FileSession));
        }
        if (!Groupings.allCompleted(newGroupingsDs)) {
          groupingsUpdateTimeout.current = setTimeout(() => updateGroupings(), GROUPINGS_UPDATE_TIMEOUT);
        }
        clearError();
      })
      .catch(setError);
  }, [clearError, groupings, logLabelingService, session?.type, sessionId, setError]);

  useEffect(() => {
    const hasGroupings = Object.keys(groupings).length > 0;
    if (!hasGroupings || Groupings.allCompleted(groupings)) return;

    updateGroupings();
    return () => {
      groupingsUpdateTimeout?.current && clearTimeout(groupingsUpdateTimeout.current as number);
    };
  }, [accountId, groupings, updateGroupings]);

  const createSession = useCallback(
    async (file: File) => {
      setSelectedGroupingId(SOURCE_GROUPING_NAME);
      const createdSessionData = await logLabelingService.createSession(file);
      if (sessionId) logLabelingService.deleteSession(sessionId);
      setSession(createdSessionData.session);
      if (createdSessionData.numberOfDeletedPhrases && createdSessionData.numberOfDeletedPhrases > 0 && addAlert) {
        addAlert({
          type: 'info',
          message: getDeletedPhrasesMessage(createdSessionData.numberOfDeletedPhrases, language),
          time: Date.now(),
          showed: true,
          duration: 5000,
        });
      }
      uploadDialog.hide();
      if (createdSessionData.session?.id) {
        loadSessionData(createdSessionData.session.id, createdSessionData.session.type);
      }
    },
    [logLabelingService, sessionId, loadSessionData, addAlert, uploadDialog, language]
  );

  const uploadFromDialog = useCallback(async () => {
    if (sessionId) logLabelingService.deleteSession(sessionId);
    const sessionData = await logLabelingService.uploadDialogs();
    setSession(sessionData);
    uploadDialog.hide();
  }, [logLabelingService, sessionId, uploadDialog]);

  const startGrouping = useCallback(
    (parameters: GroupingRequestData) => {
      if (!sessionId) return;
      load(logLabelingService.executeGrouping(sessionId, parameters))
        .then(grouping => {
          setGroupings(Groupings.addGrouping(groupings, grouping));
          if (grouping.completed) {
            setSelectedGrouping(grouping);
            setSelectedGroupingId(String(grouping.groupingId));
          }
          clearError();
        })
        .catch(setError);
    },
    [clearError, groupings, load, logLabelingService, sessionId, setError]
  );

  const updateStagingIntents = useCallback(
    () =>
      logLabelingService
        .getStagingIntents()
        .then(intents => {
          clearError();
          dispatch({ type: 'UPDATE_INTENTS', allGroups: intents.map(Groups.toGroupStagingIntent) });
        })
        .catch(setError),
    [clearError, dispatch, logLabelingService, setError]
  );

  const deletePhrases = useCallback(
    (indexes: string[]) => {
      dispatch({ type: 'DELETE_PHRASES', indexes });
      if (sessionId && state.mode !== LogLabelingMode['STAGING']) {
        load(logLabelingService.deletePhrases(sessionId, indexes))
          .then(clearError)
          .then(updateGroupings)
          .catch(setError);
        return;
      }
      const selectedIntent = state.groups[state.selectedGroupIds[0]];
      if (!selectedIntent?.intentId) return;
      const phrasesValues = indexes.map(phraseId => state.phrases[phraseId]?.text);
      const deleteData = {
        intentId: selectedIntent.intentId,
        phrases: phrasesValues,
      };
      load(logLabelingService.deleteStagingPhrases(deleteData))
        .then(() => {
          updateStagingIntents();

          clearError();
        })
        .catch(setError);
    },
    [
      clearError,
      dispatch,
      load,
      logLabelingService,
      sessionId,
      setError,
      state.groups,
      state.mode,
      state.phrases,
      state.selectedGroupIds,
      updateGroupings,
      updateStagingIntents,
    ]
  );

  const clearPhrases = useCallback(
    (indexes: string[]) => {
      if (!sessionId) return;
      dispatch({ type: 'CLEAR_PHRASES', indexes });
      load(logLabelingService.clearPhrases(sessionId, indexes)).then(clearError).catch(setError);
    },
    [clearError, dispatch, load, logLabelingService, sessionId, setError]
  );

  const cleanPhrases = useCallback(
    (options: PhrasesCleaningRequestData) => {
      if (!session || !session.id) return Promise.reject();
      return logLabelingService
        .cleanPhrases(session.id, options)
        .then(data => {
          if (addAlert) {
            addAlert({
              type: 'info',
              message: getProcessedPhrasesMessage(
                data.numberOfDeletedPhrases || 0,
                data.numberOfUpdatedPhrases || 0,
                language
              ),
              time: Date.now(),
              duration: 5000,
              showed: true,
            });
          }
          session.id && logLabelingService.getSession(session.id);
        })
        .then(() => {
          session.id && loadSessionData(session.id, session.type);
          clearError();
          clearDataDialog.hide();
        })
        .catch(setError);
    },
    [addAlert, clearDataDialog, clearError, language, loadSessionData, logLabelingService, session, setError]
  );

  const stagePhrases = useCallback(
    (indexes: string[], intentId: number) => {
      if (!sessionId) return;
      dispatch({ type: 'STAGE_PHRASES', indexes, intentId });
      load(logLabelingService.stagePhrases(sessionId, intentId, indexes))
        .then(updateGroupings)
        .then(clearError)
        .catch(setError);
    },
    [clearError, dispatch, load, logLabelingService, sessionId, setError, updateGroupings]
  );

  const moveStagedPhrases = useCallback(
    (phrasesIds: string[], toIntentId: number) => {
      const selectedIntent = state.groups[state.selectedGroupIds[0]];
      if (!selectedIntent?.intentId) return;
      const phrasesValues = phrasesIds.map(phraseId => state.phrases[phraseId]?.text);
      dispatch({ type: 'STAGE_PHRASES', indexes: phrasesIds, intentId: toIntentId });
      load(
        logLabelingService.moveStagingPhrases({
          fromIntentId: selectedIntent.intentId,
          phrases: phrasesValues,
          toIntentId,
        })
      )
        .then(clearError)
        .then(updateGroupings)
        .catch(setError);
    },
    [
      clearError,
      dispatch,
      load,
      logLabelingService,
      setError,
      state.groups,
      state.phrases,
      state.selectedGroupIds,
      updateGroupings,
    ]
  );

  const stageAllFilteredPhrases = useCallback(() => {
    if (!sessionId || typeof selectedGroupingId !== 'string') return;
    const indexes = Groups.getAllPhraseIdxs(state.filteredAllGroups);
    dispatch({ type: 'STAGE_PHRASES', indexes });
    load(logLabelingService.stageGroupingPhrases(sessionId, selectedGroupingId, indexes))
      .then(clearError)
      .then(updateGroupings)
      .then(() => dispatch({ type: 'SET_METHOD', method: 'staging' }))
      .catch(setError);
  }, [
    clearError,
    dispatch,
    load,
    logLabelingService,
    selectedGroupingId,
    sessionId,
    setError,
    state.filteredAllGroups,
    updateGroupings,
  ]);

  const applyChanges = useCallback(() => {
    if (!sessionId) return;
    dispatch({ type: 'APPLY_STAGED_PHRASES' });
    dispatch({ type: 'SET_METHOD', method: 'source' });
    load(logLabelingService.applyStaged(sessionId)).then(clearError).catch(setError);
  }, [clearError, dispatch, load, logLabelingService, sessionId, setError]);

  const setTab = useCallback(
    (value: string) => {
      if (value === 'staging') {
        if (state.mode === LogLabelingMode['STAGING']) return;
        dispatch({ type: 'SET_METHOD', method: 'staging' });
        return;
      }
      if (value === 'labeling' || session?.type !== TypeEnum.ChatHistorySession) {
        setSelectedGroupingId(SOURCE_GROUPING_NAME);
        dispatch({ type: 'SET_METHOD', method: 'source' });
      }
      let targetGrouping: Groupings.GroupingTreeNode | undefined;
      for (const grouping of Object.values(groupings)) {
        if (grouping.createdBySystem && grouping.groupsCount !== 0) {
          targetGrouping = grouping;
          break;
        }
      }
      setSelectedGroupingId(targetGrouping?.nodeId);
      if (sessionId && targetGrouping?.id)
        load(logLabelingService.getGroupingGroups(sessionId, targetGrouping?.id))
          .then(allGroups => {
            dispatch({
              type: 'SET_METHOD',
              method: targetGrouping?.parameters.method || 'source',
              allGroups,
            });
            clearError();
          })
          .catch(setError);
      return;
    },
    [clearError, dispatch, groupings, load, logLabelingService, session?.type, sessionId, setError, state.mode]
  );

  const deleteGrouping = useCallback(
    (groupingId: string) => {
      if (!sessionId) return;
      const groupingToRemove = groupings[groupingId];
      load(logLabelingService.deleteGrouping(sessionId, groupingToRemove.id))
        .then(() => {
          const updatedGroupings = Groupings.removeGrouping(groupings, groupingToRemove);
          setGroupings(updatedGroupings);
          //установка новой выбранной группировки после удаления
          //если сессия создана из файла, просто ставим на неразмеченные файлы
          if (session.type === TypeEnum.FileSession) {
            return setSelectedGroupingId(SOURCE_GROUPING_NAME);
          }
          //если сессия создана из диалога, проверяем сначала группу размеченных на наличие фраз, если их там нет, то ставим неразмеченные
          let nonEmptyGroupingName;
          const systemGroupingsNotEmpty = Object.values(groupings).filter(
            grouping => grouping.createdBySystem && grouping.groupsCount > 0
          );
          if (!systemGroupingsNotEmpty.length) return setSelectedGrouping(undefined);
          if (systemGroupingsNotEmpty.length === 1) {
            nonEmptyGroupingName = systemGroupingsNotEmpty[0].nodeId;
          } else {
            nonEmptyGroupingName = systemGroupingsNotEmpty.find(grouping => grouping.name === 'Classification')?.nodeId;
          }
          return setSelectedGroupingId(nonEmptyGroupingName);
        })
        .catch(setError);
    },
    [groupings, load, logLabelingService, session?.type, sessionId, setError]
  );

  const getStagingIntents = useCallback(async () => {
    load(logLabelingService.getStagingIntents())
      .then(intents => {
        clearError();
        dispatch({ type: 'SET_METHOD', method: 'staging', allGroups: intents.map(Groups.toGroupStagingIntent) });
      })
      .catch(setError);
  }, [clearError, dispatch, load, logLabelingService, setError]);

  return (
    <LogLabelingContext.Provider
      value={{
        state,
        dispatch,
        sessionId,
        session,
        createSession,
        stageAllFilteredPhrases,
        groupings,
        selectedGroupingId,
        selectedGrouping,
        selectGrouping: setSelectedGroupingId,
        startGrouping,
        setTab,
        load,
        loadDialog,
        uploadFromDialog,
        loadFile,
        isLoading,
        isDialogsUploading,
        isFileUploading,
        setError,
        clearError,
        errorMessage,
        deletePhrases,
        clearPhrases,
        cleanPhrases,
        stagePhrases,
        moveStagedPhrases,
        applyChanges,
        uploadDialog,
        labelingDialog,
        selectIntentDialog,
        stagingApplyDialog,
        clearDataDialog,
        stageAllDialog,
        deleteGrouping,
        getStagingIntents,
        updateStagingIntents,
      }}
    >
      {typeof errorMessage === 'string' ? <div style={{ color: 'red' }}>{errorMessage}</div> : null}
      {props.children}
      <Spinner size='4x' hidden={!isLoading} />
    </LogLabelingContext.Provider>
  );
};

export const useLogLabelingContext = () => useContext(LogLabelingContext);
