import React, { FunctionComponent, useState, useEffect, useContext, SyntheticEvent, useCallback } from 'react';
import classNames from 'classnames';
import { SpelledWordsData } from '../../../Caila/api/client';
import { Popover, InputText, Icon, Button } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { AppContext } from '../../../Caila/components/AppContext';
import { useToggle, useError } from '../../../Caila/utils';

interface UserDictionary {
  [key: string]: number;
}

interface SpelledWordsProps {
  spelledWords?: SpelledWordsData[];
  sentence?: string;
  onChange?: () => unknown;
  spellingCorrection: boolean;
  enableSpellingCorrection: () => unknown;
}
const SpelledWords: FunctionComponent<SpelledWordsProps> = ({
  spelledWords = [],
  sentence: sentenceProps = '',
  onChange = () => {},
  spellingCorrection,
  enableSpellingCorrection,
}) => {
  const [openedWordIndex, setOpenedWordIndex] = useState<number>();
  const { t } = i18nTranslation('Widget');
  const [words, setWords] = useState(spelledWords);
  const [wordToAdd, setWordToAdd] = useState<string>('');
  const [errorMessage, setError, clearError] = useError();
  const [sentence, setSentence] = useState(sentenceProps);
  const [isOpenDialog, openDialog, closeDialog] = useToggle(false);
  const [isOpenedSpellingPopover, openSpellingPopover, closeSpellingPopover] = useToggle(false);
  const { SpellerApi, accountId, projectShortName } = useContext(AppContext);

  useEffect(() => {
    setWords(sortWords(spelledWords));
  }, [spelledWords]);

  useEffect(() => {
    setSentence(sentenceProps);
  }, [sentenceProps, spellingCorrection]);

  const checkClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e?.target as HTMLElement;
      const popover = document.querySelector('.popover-inner');
      if (popover && target?.classList[0] !== 'correction' && !popover.contains(target as Node)) {
        closePopover();
        closeSpellingPopover();
      }
    },
    [closeSpellingPopover]
  );

  useEffect(() => {
    document.addEventListener('click', checkClickOutside);
    return () => document.removeEventListener('click', checkClickOutside);
  }, [checkClickOutside]);

  const closePopover = () => setOpenedWordIndex(undefined);

  const onSuccess = () => {
    clearError();
    onChange();
  };

  const onClickWord = (index?: number) => () => {
    setOpenedWordIndex(index === openedWordIndex ? undefined : index);
  };

  const selectVariant = (wordIndex: number, variant: string) => {
    SpellerApi.addWord(accountId, projectShortName, { word: variant }).then(onSuccess).catch(setError);
    setWords(words.filter((word, i) => i !== wordIndex));
    closePopover();
  };

  const deleteWord = (index: number, variant: string) => {
    SpellerApi.deleteWord(accountId, projectShortName, { word: variant }).then(onSuccess).catch(setError);
    setWords(words.filter((word, i) => i !== index));
  };

  const clickAddToDictionary = (index: number) => () => {
    setWordToAdd(words[index].source);
    openDialog();
    closePopover();
  };

  const addToDictionary = () => {
    closeDialog();
    SpellerApi.addWord(accountId, projectShortName, { word: wordToAdd }).then(onSuccess).catch(setError);
  };

  const addExclusion = (index: number) => () => {
    SpellerApi.addExclusion(accountId, projectShortName, { word: words[index].source }).then(onSuccess).catch(setError);
  };
  const deleteExclusion = (index: number) => () => {
    SpellerApi.deleteExclusion(accountId, projectShortName, { word: words[index].source })
      .then(onSuccess)
      .catch(setError);
  };

  /*NEVER USED
  const deleteDictionary = () => {
    SpellerApi.deleteSpellerDictionary(accountId, projectShortName).then(onSuccess).catch(setError);
  };*/

  const sortWords = (words: SpelledWordsData[]) => {
    const newWords = [...words];
    newWords.sort((a, b) => (a.startPos > b.startPos ? 1 : -1));
    return newWords;
  };

  const openSettings = () => {
    const customEvent = new CustomEvent('open_settings_current_project');
    window.dispatchEvent(customEvent);
    closeSpellingPopover();
  };

  const checkWordExclusion = (index: number) => Boolean(words[index].excluded);

  return (
    <div className='spelled-container'>
      <div className='description'>
        <span>{t('titleText')}</span>
        <div
          className={classNames('icon', { icon__enabled: spellingCorrection })}
          id='spelling-popover'
          onClick={e => setTimeout(openSpellingPopover, 0)}
        >
          <Icon name='faFont' size='sm' />
        </div>
        <Popover
          isOpen={isOpenedSpellingPopover}
          target='spelling-popover'
          title={t(spellingCorrection ? 'spellingPopoverTitleEnabled' : 'spellingPopoverTitleDisabled')}
          onCancelClick={closeSpellingPopover}
          className='spelling-enable-popover'
        >
          {t('spellingPopoverDescription')}
          <div className='spelling-enable-popover__buttons'>
            <Button
              color='success'
              size='sm'
              onClick={() => (spellingCorrection ? openSettings() : enableSpellingCorrection())}
            >
              {t(spellingCorrection ? 'spellingPopoverButtonSetting' : 'spellingPopoverButtonEnable')}
            </Button>
            <div>
              <a href={t('spellingPopoverLink')} target='_blank' rel='noopener noreferrer'>
                {t('spellingPopoverLinkText')}
              </a>
              <Icon name='faExternalLinkAlt' size='sm' color='primary' />
            </div>
          </div>
        </Popover>
      </div>
      {errorMessage && <p className='spelled-container__error'>{errorMessage}</p>}
      <span className='spelled-sentence'>
        {spellingCorrection && words && words.length ? (
          words.map((word, i) => {
            return (
              <span key={`spelledWord-${i}`}>
                {i === 0 && word.startPos > 0 ? sentence.substring(0, word.startPos) : ''}
                <span
                  className={classNames('correction', { selected: openedWordIndex === i })}
                  onClick={onClickWord(i)}
                  data-test-id='Widget.SpellingCorrection.WordToCorrect'
                  id={`spelled-popover-${i}`}
                >
                  {sentence.substring(word.startPos, word.endPos)}
                </span>
                <Popover
                  isOpen={openedWordIndex === i}
                  target={`spelled-popover-${i}`}
                  placement='left'
                  className='spelling-popover'
                >
                  {word.variants.some(variant => variant.spelledBy === 'local') && (
                    <>
                      <span className='spelling-popover__item dictionary'>{t('userDictionary')}</span>
                      <div className='spelling-popover__separator' />
                      {word.variants
                        .filter(variant => variant.spelledBy === 'local')
                        .map(variant => (
                          <WordItem
                            index={i}
                            selectVariant={selectVariant}
                            word={variant.word}
                            deleteWord={deleteWord}
                            source={sentence.substring(word.startPos, word.endPos)}
                            key={`local_word-${i}-${variant.word}`}
                            testId='local'
                          />
                        ))}
                      <div className='spelling-popover__separator' />
                    </>
                  )}
                  {word.variants.some(variant => variant.spelledBy === 'mlps') && (
                    <>
                      <span className='spelling-popover__item dictionary'>{t('systemDictionary')}</span>
                      <div className='spelling-popover__separator' />
                      {word.variants
                        .filter(variant => variant.spelledBy === 'mlps')
                        .map(variant => (
                          <WordItem
                            index={i}
                            selectVariant={selectVariant}
                            word={variant.word}
                            source={sentence.substring(word.startPos, word.endPos)}
                            key={`mlps_word-${i}-${variant.word}`}
                            testId='mlps'
                          />
                        ))}
                      <div className='spelling-popover__separator' />
                    </>
                  )}
                  <span
                    className='spelling-popover__item'
                    onClick={checkWordExclusion(i) ? deleteExclusion(i) : addExclusion(i)}
                    data-test-id='Widget.SpellingCorrection.Popover.AddExclusion'
                  >
                    {t('notCorrect')}
                    {checkWordExclusion(i) && <Icon name='faCheck' size='sm' color='success' className='success' />}
                  </span>
                  <div className='spelling-popover__separator' />
                  <span
                    className={classNames('spelling-popover__item', {
                      disabled: checkWordExclusion(i),
                    })}
                    onClick={checkWordExclusion(i) ? undefined : clickAddToDictionary(i)}
                    data-test-id='Widget.SpellingCorrection.Popover.AddToDictionary'
                  >
                    {t('addToDictionary')}
                  </span>
                </Popover>
                <span>
                  {words[i + 1]
                    ? sentence.substring(word.endPos, words[i + 1].startPos)
                    : sentence.substring(word.endPos, sentence.length)}
                </span>
              </span>
            );
          })
        ) : (
          <span>{sentence}</span>
        )}
      </span>
      <AddWordDialog
        isOpen={isOpenDialog}
        close={closeDialog}
        word={wordToAdd}
        changeWord={(word: string) => setWordToAdd(word)}
        addWord={addToDictionary}
      />
    </div>
  );
};

interface AddWordDialogProps {
  isOpen: boolean;
  close: () => unknown;
  addWord: () => unknown;
  changeWord: (word: string) => unknown;
  word: string;
}
const AddWordDialog: FunctionComponent<AddWordDialogProps> = ({ isOpen, close, addWord, word, changeWord }) => {
  const { t } = i18nTranslation('Widget');
  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={close}
      onActionClick={addWord}
      buttonCancelTestId='Widget.AddToDictionary.CloseButton'
      buttonSubmitTestId='Widget.AddToDictionary.AddButton'
      buttonCancelText={t('addToDictonaryModalCancel')}
      buttonSubmitText={t('addToDictonaryModalSubmit')}
      title={t('addToDictionaryModalTitle')}
      disableActionButtonAutoFocus
    >
      <div>
        <InputText value={word} onChange={val => changeWord(val)} dataTestId='Widget.AddToDictionary.Input' autoFocus />
      </div>
    </Modal>
  );
};

interface WordItemProps {
  index: number;
  selectVariant: (index: number, word: string) => unknown;
  deleteWord?: (index: number, word: string) => unknown;
  word: string;
  testId?: string;
  source?: string;
}
const WordItem: FunctionComponent<WordItemProps> = React.memo(
  ({ index, selectVariant, deleteWord, word, source, testId = 'WordItem' }) => {
    const onClick = useCallback(() => selectVariant(index, word), [index, selectVariant, word]);
    const onDelete = useCallback(
      (e: SyntheticEvent) => {
        e.stopPropagation();
        deleteWord && deleteWord(index, word);
      },
      [deleteWord, index, word]
    );
    return (
      <span
        className={classNames('spelling-popover__item', { active: source === word })}
        onClick={onClick}
        data-test-id={`Widget.SpellingCorrection.Popover.WordVariant.${testId}`}
      >
        {word} {deleteWord && <Icon name='faTimesCircle' size='sm' onClick={onDelete} className='delete' />}
      </span>
    );
  }
);

export default SpelledWords;
