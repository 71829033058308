import React from 'react';
import Modal from 'components/Modal';

import { t } from 'localization';

interface DeleteGroupModalInterface {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}
const DeleteGroupModal = function ({ isOpen, onCancel, onSubmit }: DeleteGroupModalInterface) {
  return (
    <Modal
      isOpen={isOpen}
      data-test-id='RightSideMenu:openDelete:modal'
      title={t(`RightSideMenu:delete_group_title`)}
      buttonSubmitColor='danger'
      buttonSubmitText={t(`RightSideMenu:delete_group_submit`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Cancel')}
      onCancelClick={onCancel}
      onActionClick={onSubmit}
      buttonSubmitTestId='RightSideMenu:modal:submitDelete'
      buttonCancelTestId='RightSideMenu:modal:cancelDelete'
    >
      <p>{t(`RightSideMenu:delete_group_text`)}</p>
    </Modal>
  );
};

export default React.memo(DeleteGroupModal);
