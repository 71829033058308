import React, { useEffect, useCallback } from 'react';
import { useToggle, Banner } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import { usePermissionAccessChange } from '@just-ai/just-ui/dist/utils/permissions';

import { t } from 'localization';
import { isEuroInstance } from 'isAccessFunction';

import { ClipboardService } from 'services/ClipboardService';

import styles from './styles.module.scss';

const PermissionPointer = () => (
  <svg width='78' height='61' viewBox='0 0 78 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M75.6689 60.7072C76.4711 60.9142 77.2891 60.4318 77.4961 59.6296C77.7031 58.8274 77.2206 58.0094 76.4185 57.8024L75.6689 60.7072ZM7.5824 0.983752C6.80772 0.690209 5.94176 1.08024 5.64821 1.85492L0.864653 14.479C0.57111 15.2537 0.961146 16.1196 1.73582 16.4132C2.5105 16.7067 3.37646 16.3167 3.67001 15.542L7.92206 4.32061L19.1435 8.57266C19.9181 8.86621 20.7841 8.47617 21.0777 7.70149C21.3712 6.92682 20.9812 6.06085 20.2065 5.76731L7.5824 0.983752ZM76.4185 57.8024C46.6555 50.1225 20.8929 29.4662 8.41856 1.77042L5.68322 3.00244C18.5575 31.5861 45.0878 52.8163 75.6689 60.7072L76.4185 57.8024Z'
      fill='white'
    />
  </svg>
);

type CopyPastePermissionTipModalProps = {
  description?: string;
};
const CopyPastePermissionTipModal = ({ description }: CopyPastePermissionTipModalProps) => {
  const [modalOpened, showModal, closeModal] = useToggle(false);

  usePermissionAccessChange(
    'clipboard-read',
    useCallback(status => status.state === 'granted' && closeModal(), [closeModal])
  );

  useEffect(() => ClipboardService.onClipboardPermissionError(showModal), [closeModal, showModal]);

  return (
    <Modal
      isOpen={modalOpened}
      centered
      className={styles.CopyPastePermissionTipModal}
      title={t('CopyPastePermissionTipModal:Title')}
      buttonSubmitText={t('CopyPastePermissionTipModal:Button:Submit')}
      onActionClick={closeModal}
      onClickCloseIcon={closeModal}
      data-test-id='CopyPastePermissionTipModal'
      titleTestId='CopyPastePermissionTipModal.header'
      buttonSubmitTestId='CopyPastePermissionTipModal.submitButton'
    >
      <div className={styles.Content}>
        <div className={styles.Pointer}>
          <PermissionPointer />
        </div>
        <img
          src={t(
            isEuroInstance()
              ? 'CopyPastePermissionTipModal:Images:Base:Tovie'
              : 'CopyPastePermissionTipModal:Images:Base'
          )}
          loading='eager'
          alt='copy paste tip'
        />
        {description && <span>{description}</span>}
        <Banner
          type='warning'
          withIcon
          BannerText={() => (
            <div className={styles.BannerContent}>
              <span>{t('CopyPastePermissionTipModal:Content:Banner')}</span>
              <img
                src={isEuroInstance() ? '/img/jgraph/copyPasteWarningTovie.png' : '/img/jgraph/copyPasteWarning.png'}
                loading='eager'
                alt='copy paste tip'
              />
            </div>
          )}
        />
      </div>
    </Modal>
  );
};

CopyPastePermissionTipModal.displayName = 'CopyPastePermissionTipModal';

export default React.memo(CopyPastePermissionTipModal);
