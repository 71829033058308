import React, { useCallback, useState } from 'react';
import { RadioButton, Banner } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import { AppLogger } from '@just-ai/logger';

import { t } from 'localization';

import { useDeleteKnowledgeBasesApiApi } from '../../domain/api';

import styles from './styles.module.scss';

type DeleteType = 'connection' | 'all';

interface DeleteIntegrationModalProps {
  integrationId: number;
  secretName: string;
  isOpen: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}
export const DeleteIntegrationModal = React.memo(
  ({ secretName, integrationId, isOpen, onSubmit, onCancel }: DeleteIntegrationModalProps) => {
    const [strategy, setStrategy] = useState<DeleteType>('connection');

    const [deleteKnowledgeBasesState, deleteKnowledgeBasesApiCall] = useDeleteKnowledgeBasesApiApi();

    const onInnerSubmit = useCallback(() => {
      deleteKnowledgeBasesState.reset();
      return deleteKnowledgeBasesApiCall(integrationId, strategy === 'all')
        .then(onSubmit)
        .catch(AppLogger.createNetworkHandler('deleteKnowledgeBasesApi'));
    }, [strategy, integrationId, deleteKnowledgeBasesState, deleteKnowledgeBasesApiCall, onSubmit]);

    return (
      <Modal
        size='md'
        centered
        className={styles.DeleteIntegrationModal}
        formClassName={styles.modalContent}
        data-test-id='KnowledgeBaseLLM.DeleteConnectionModal'
        isOpen={isOpen}
        title={t('KnowledgeBaseLLM:DeleteConnectionModal:Title')}
        buttonCancelText={t('KnowledgeBaseLLM:DeleteConnectionModal:Cancel')}
        buttonCancelColor='secondary'
        buttonCancelTestId='KnowledgeBaseLLM.DeleteConnectionModal.cancelButton'
        onCancelClick={onCancel}
        onActionClick={onInnerSubmit}
        buttonSubmitTestId='KnowledgeBaseLLM.DeleteConnectionModal.submitButton'
        buttonSubmitText={t('KnowledgeBaseLLM:DeleteConnectionModal:Submit')}
        buttonSubmitColor='danger'
        inProgress={deleteKnowledgeBasesState.loading}
        disableActionButtonAutoFocus
        FixedBannerBottom={() =>
          deleteKnowledgeBasesState.error ? (
            <Banner type='danger' BannerText={() => <span>{deleteKnowledgeBasesState.error}</span>} />
          ) : null
        }
      >
        <RadioButton
          name='strategy'
          value='connection'
          checked={strategy === 'connection'}
          label={t('KnowledgeBaseLLM:DeleteConnectionModal:OnlyConnection')}
          onClick={() => setStrategy('connection')}
          data-test-id='KnowledgeBaseLLM.DeleteConnectionModal.onlyConnection'
        />
        <RadioButton
          name='strategy'
          value='all'
          checked={strategy === 'all'}
          label={
            <span
              dangerouslySetInnerHTML={{ __html: t('KnowledgeBaseLLM:DeleteConnectionModal:All', { secretName }) }}
            />
          }
          onChange={setStrategy}
          data-test-id='KnowledgeBaseLLM.DeleteConnectionModal.all'
        />
      </Modal>
    );
  }
);

DeleteIntegrationModal.displayName = 'DeleteIntegrationModal';
