import { SuggestedVoicesDTO } from '../../../../../modules/TestTtsWidget/api/client';
import { azureTtsLocales, googleLocales, yandexLocales } from '../locale';

export const getDefaultTtsVoiceByVoiceLangCode = () => {};

export const getDefaultTtsLocaleByVoiceLangCode = (voice: string) => {
  if (!voice) return;
  const locales = Array.from(new Set([...yandexLocales, ...googleLocales, ...azureTtsLocales])).sort(
    (a, b) => b.length - a.length
  );

  //В названии голоса может быть префикс определяющий язык этого голоса (проход от более длинных префиксов к более коротким, т.к. есть префиксы включающие друг друга, например 'zh' и 'zh-TW')

  for (let langLocale of locales) {
    if (voice.startsWith(langLocale)) return langLocale;
  }
};

export const getDefaultTTSOptions = (
  projectLanguage: string,
  ttsType: string,
  asrType: string,
  asrLanguageCode: string,
  selectedAsrProvider: string,
  ttsVoices: SuggestedVoicesDTO['providers']
) => {
  let ttsLanguageCode;
  let voice;

  const providerVoices =
    ttsVoices
      .find(provider => provider.provider === ttsType)
      ?.languages?.filter(({ language }) => language?.includes(projectLanguage))
      .map(langVoices => langVoices?.voices || [])
      .flat() ?? [];

  if (ttsType === 'GOOGLE') {
    switch (projectLanguage.toLowerCase()) {
      case 'ru': {
        ttsLanguageCode = 'ru-RU';
        voice = 'ru-RU-Wavenet-B';
        break;
      }
      case 'en': {
        ttsLanguageCode = 'en-US';
        voice = 'en-US-Wavenet-A';
        break;
      }
      case 'zh': {
        ttsLanguageCode = 'zh';
        voice = 'cmn-CN-Wavenet-B';
        break;
      }
      case 'pt': {
        ttsLanguageCode = 'pt-PT';
        voice = 'pt-PT-Wavenet-B';
        break;
      }
      default: {
        ttsLanguageCode = 'en-US';
        voice = voice = providerVoices.find(voice => voice?.includes('-Wavenet-A'));
        if (!voice) voice = 'en-US-Wavenet-A';
      }
    }
    if (asrType === ttsType) {
      ttsLanguageCode = asrLanguageCode;
    }

    return {
      ttsLanguageCode,
      voice,
      speakingRate: 1,
      pitch: 0,
      volumeGainDb: 0,
    };
  }
  if (ttsType === 'YANDEX') {
    switch (projectLanguage.toLowerCase()) {
      case 'ru': {
        ttsLanguageCode = 'ru-RU';
        voice = 'alena';
        break;
      }
      case 'en': {
        ttsLanguageCode = 'en-US';
        voice = 'alyss';
        break;
      }
      default: {
        ttsLanguageCode = 'en-US';
        voice = 'alyss';
      }
    }
    if (selectedAsrProvider === ttsType) {
      ttsLanguageCode = asrLanguageCode;
    }

    return {
      ttsLanguageCode,
      voice,
      speakingRate: 1,
    };
  }
  if (ttsType === 'AZURE') {
    switch (projectLanguage.toLowerCase()) {
      case 'ru': {
        ttsLanguageCode = 'ru-RU';
        voice = 'ru-RU-DariyaNeural';
        break;
      }
      case 'en': {
        ttsLanguageCode = 'en-US';
        voice = 'en-US-AriaNeural';
        break;
      }
    }

    return {
      ttsLanguageCode,
      voice,
    };
  }
  if (ttsType === 'ZITECH') {
    voice = 'Alex';

    return {
      voice,
    };
  }
  if (ttsType === 'AIMYVOICE') {
    voice = 'Татьяна';

    return {
      voice,
    };
  }
  if (ttsType === 'SBER') {
    return {
      voice: providerVoices[0],
    };
  }
  if (ttsType === 'AUDIOGRAM') {
    voice = providerVoices[0] ?? 'borisova';
    return {
      voice,
      modelType: 'light',
      voiceStyle: 'neutral',
    };
  }
};
