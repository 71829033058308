import { Ace } from 'ace-builds';
import { trim } from 'lodash';

export class ButtonsCompleter {
  completionStrings: string[];
  KEYWORDS: string[];
  NAME: string;
  priority: number;

  constructor(completionStrings: string[], KEYWORDS: string[], NAME: string, priority = 10_000) {
    this.completionStrings = completionStrings;
    this.KEYWORDS = KEYWORDS;
    this.NAME = NAME;
    this.priority = priority;
  }

  public isApplicable = (tokens: string[], line: string) => {
    const notEmptyTokensInRow = tokens.filter(token => token);
    if (notEmptyTokensInRow.length > 1) return false;
    const token = notEmptyTokensInRow[0] ?? '';
    if (['', 'buttons:'].includes(token.trim())) return false;
    return line.endsWith(token) && !line.match(/(.+->|{ text:)/);
  };

  public getCompletions = (tokensInRow: Ace.Token[]) => {
    const lastTokenValue = tokensInRow[tokensInRow.length - 1]?.value.trim() ?? '';

    if (lastTokenValue.startsWith('{')) {
      return [
        {
          value: this.getObjButtonsTemplateValue(lastTokenValue),
          name: lastTokenValue,
          score: 10000,
          meta: this.NAME,
          completer: lastTokenValue.length > 0 ? this.customCompleter(lastTokenValue, 7) : {},
        },
      ];
    }

    return [
      {
        value: this.getButtonsTemplateValue(lastTokenValue),
        name: lastTokenValue,
        score: 10000,
        meta: this.NAME,
        completer: lastTokenValue.length > 0 ? this.customCompleter(lastTokenValue, 1) : {},
      },
    ];
  };

  private getButtonsTemplateValue = (value: string): string => {
    const trimmedValue = trim(value, ' "');
    return `"${trimmedValue}" -> /StatePath`;
  };

  private getObjButtonsTemplateValue = (value: string): string => {
    return `{ text: "${value
      .replace('{', '')
      .trim()}", transition: "/StatePath",  request_location: true, one_time_keyboard: false }`;
  };

  private customCompleter = (value: string, setCursorPositionOffset?: number) => {
    return {
      insertMatch: function (
        insertEditor: Ace.Editor,
        data: { value: string; name: string; score: number; meta: string }
      ) {
        let insertValue = data.value;
        let lastPosition = insertEditor.selection.getCursor();

        insertEditor.navigateLineStart();
        let startPosition = insertEditor.selection.getCursor();

        insertEditor.session.replace(
          {
            start: { row: startPosition.row, column: startPosition.column },
            end: { row: lastPosition.row, column: lastPosition.column },
          } as Ace.Range,
          insertValue
        );
        if (Number.isInteger(setCursorPositionOffset)) {
          insertEditor.moveCursorTo(
            startPosition.row,
            startPosition.column + data.name.length + (setCursorPositionOffset ?? 0)
          );
        }
      },
    };
  };
}
