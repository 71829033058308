import React, { FunctionComponent, useState } from 'react';
import { InputText } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import IntentsSelector from './IntentsSelector';
import { ModalControl } from '../../utils';
import { Button, Spinner } from '@just-ai/just-ui/dist';

export type SelectIntentModalProps = {
  isShown?: ModalControl['isShown'];
  hide?: ModalControl['hide'];
  sessionId?: number;
  onSuccess?: (intentId: number) => unknown;
  titleText?: string;
  phraseText?: string;
  cancelButtonText?: string;
  addButtonText?: string;
  phrase?: string;
  loading?: boolean;
  onChangePhrase?: (phrase: string) => unknown;
  showPhraseEdit?: boolean;
  error?: string | null;
  disabledAddButton?: boolean;
};

const SelectIntentModal: FunctionComponent<SelectIntentModalProps> = ({
  isShown,
  sessionId,
  hide = () => {},
  onSuccess = () => {},
  titleText,
  phraseText,
  cancelButtonText,
  addButtonText,
  phrase,
  loading,
  onChangePhrase = () => {},
  showPhraseEdit,
  error,
  disabledAddButton,
}) => {
  const [intentId, setIntentId] = useState<number>();
  const isIntentSelected = typeof intentId === 'number';
  if (!isShown) return null;

  const addPhrases = () => {
    if (!isIntentSelected) return;
    onSuccess(Number(intentId));
  };

  const changePhrase = (phrase: string) => {
    onChangePhrase(phrase);
  };

  return (
    <Modal
      isOpen={isShown}
      size='lg'
      title={titleText}
      disableActionButtonAutoFocus
      disableKeyboard
      customFooter={
        <div className='modal-base-buttons'>
          <Button data-test-id='IntentsModal.CancelButton' onClick={hide} color='primary' outline>
            {cancelButtonText}
          </Button>
          <Button
            data-test-id='IntentsModal.AddButton'
            onClick={addPhrases}
            color='primary'
            disabled={!isIntentSelected || disabledAddButton}
          >
            {addButtonText}
          </Button>
        </div>
      }
    >
      {loading && <Spinner size='4x' />}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {showPhraseEdit && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px 0' }}>
          <span style={{ fontWeight: 600, paddingRight: 15 }}>{phraseText}</span>
          <InputText data-test-id='IntentsModal.IntentInput' value={phrase} onChange={changePhrase} />
        </div>
      )}
      <IntentsSelector sessionId={sessionId || undefined} onChange={intentId => setIntentId(intentId)} />
    </Modal>
  );
};

export default SelectIntentModal;
