// eslint-disable-next-line no-restricted-imports
import { Modal as ModalJustUI, ModalProps } from '@just-ai/just-ui';
import { t } from '../../localization';
import { FC } from 'react';

const Modal: FC<ModalProps> = ({
  buttonCancelText = t('Modal:Button:Cancel'),
  buttonSubmitText = t('Modal:Button:Save'),
  ...props
}) => <ModalJustUI buttonCancelText={buttonCancelText} buttonSubmitText={buttonSubmitText} {...props} />;

export default Modal;
