import React, { useCallback, useEffect, useState } from 'react';
import { Spinner, useToggle } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import localize, { t } from 'localization';
import { MoveStateInGroupLocalization } from './MoveStateInGroup.loc';
import { BehaviorSubject } from 'rxjs';
import { useAppDispatch } from '../../../../storeHooks';
import { moveStateInGroup } from 'reducers/JGraph.reducer/JGraphAsyncActions';

localize.addTranslations(MoveStateInGroupLocalization);

type MoveStateInGroupState = {
  what: {
    pathId: string;
    stateValue: string;
  };
  where: {
    pathId: string;
    stateValue: string;
  };
};

export const MoveStateInGroup$ = new BehaviorSubject<MoveStateInGroupState | null>(null);

export const MoveStateInGroup = React.memo(() => {
  const dispatch = useAppDispatch();
  const [whatAndWhere, setWhatAndWhere] = useState<MoveStateInGroupState | null>(null);
  const [isOpenConfirmModal, openConfirmModal, closeConfirmModal] = useToggle(false);
  const [moving, setMoving] = useState(false);

  useEffect(() => {
    const sub = MoveStateInGroup$.subscribe(value => {
      setWhatAndWhere(value);
      !!value ? openConfirmModal() : closeConfirmModal();
    });
    return () => {
      sub.unsubscribe();
    };
  }, [closeConfirmModal, openConfirmModal]);

  const saveAfterConfirm = useCallback(async () => {
    if (whatAndWhere) {
      setMoving(true);
      await dispatch(moveStateInGroup({ what: whatAndWhere.what.pathId, where: whatAndWhere.where.pathId }));
      MoveStateInGroup$.next(null);
      setMoving(false);
    }
  }, [dispatch, whatAndWhere]);

  const closeConfirmModalHandler = useCallback(() => {
    closeConfirmModal();
    MoveStateInGroup$.next(null);
  }, [closeConfirmModal]);

  if (!whatAndWhere) return null;

  return (
    <>
      {moving && <Spinner />}
      <Modal
        isOpen={isOpenConfirmModal}
        title={t(`MoveStateInGroup:title`)}
        buttonSubmitColor='primary'
        buttonSubmitText={t(`MoveStateInGroup:confirmMoveToGroup`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        onCancelClick={closeConfirmModalHandler}
        onActionClick={saveAfterConfirm}
        buttonCancelDisabled={moving}
        buttonSubmitDisabled={moving}
      >
        {moving && <Spinner size='md' />}
        <p>{t(`MoveStateInGroup:body`, whatAndWhere.what.stateValue, whatAndWhere.where.stateValue)}</p>
      </Modal>
    </>
  );
});
MoveStateInGroup.displayName = 'MoveStateInGroup';
