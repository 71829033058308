import React, { FunctionComponent, useState } from 'react';
import { DatePicker, RadioButton } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import localize from '../../localization';
import { CurrentUserLanguage, useAppContext } from 'modules/Caila/components/AppContext';
import { Range } from '@just-ai/just-ui/dist/DatePicker';

const { translate } = localize;

type Props = {
  toggle(): void;
  onAction(range?: Range): void;
};

export type PeriodType = 'period' | 'allTime';

const LOCALE_BY_LANGUAGE: { [TKey in CurrentUserLanguage]: 'en' | 'ru' } = {
  eng: 'en',
  ru: 'ru',
  cn: 'en',
};

const getStartOfCurrentDay = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const GetLogModal: FunctionComponent<Props> = ({ toggle, onAction }) => {
  const { language } = useAppContext();
  const [periodType, setPeriodType] = useState<PeriodType>('period');

  const now = new Date();
  const [range, setRange] = useState<Range>(() => ({ startDate: getStartOfCurrentDay(), endDate: now }));

  const onSubmit = () => {
    if (periodType === 'period') onAction(range);
    else onAction();
    toggle();
  };

  return (
    <Modal
      isOpen
      className='channels__get-log-modal'
      onCancelClick={toggle}
      title={translate('Channels:getLogModalTitle')}
      buttonCancelColor='secondary'
      buttonSubmitColor='primary'
      buttonCancelText={translate('Cancel')}
      buttonSubmitText={translate('Channels:getLogModalGet')}
      onActionClick={onSubmit}
    >
      <RadioButton
        name='periodType'
        label={translate('Channels:getLogModalPeriod')}
        onClick={() => setPeriodType('period')}
        defaultChecked
      />
      <DatePicker
        locale={LOCALE_BY_LANGUAGE[language]}
        //@ts-ignore
        onChange={range => setRange(range)}
        defaultValue={range}
        maxDate={now}
        withTime
        positionFixed
      />
      <RadioButton
        name='periodType'
        label={translate('Channels:getLogModalAllTime')}
        onClick={() => setPeriodType('allTime')}
      />
    </Modal>
  );
};
