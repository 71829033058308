import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ClickAwayListener, Icon, InputText, JustSelect, SwitchButton } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import { shift, offset, flip, ComputePositionConfig } from '@floating-ui/react-dom';
import KeyboardService from '@just-ai/nlu-modules/dist/services/KeyboardService';

import { t } from 'localization';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';
import { closeScreenCreationMenu, openScreenCreationMenu } from 'reducers/JGraph.reducer';
import {
  findScreenByPath,
  getAllStates,
  getValidKonvaName,
  transformStateNameToPath,
} from 'reducers/JGraph.reducer/Graph';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

import { addNewStateWithSave, makeNewConnectorAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';
import { getNewNameWithIndex, joinPaths } from 'modules/JGraph/utils/stageUtils';

import { TagNames, TReactionsTagNames } from '../../utils/types';
import { initialNewConnectorState, newConnectorSubject$ } from '../../hooks';

import { getLastPartOfPath } from '../../utils/themesUtils';
import { AddingSimpleBlock } from './AddingSimpleBlock';

import styles from './CreationScreenMenu.module.scss';

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'fixed',
  placement: 'right-start',
  middleware: [
    offset({
      mainAxis: -10,
      alignmentAxis: -30,
    }),
    flip(),
    shift(),
  ],
};

export const CreationScreenMenu: FC = () => {
  const { screenCreationMenu, blocks, themes } = useAppSelector(state => ({
    screenCreationMenu: state.JGraphReducer.screenCreationMenu,
    blocks: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
  }));

  const dispatch = useAppDispatch();
  const [openConfirmModal, setOpen] = useState(false);
  const [showParentSelection, setOpenParentSelection] = useState(false);
  const storeSelectedValue = useRef<TagNames | undefined>(undefined);
  const menuWrapper = useRef<HTMLDivElement>(null);
  const keyboardService = useRef(new KeyboardService());
  const busyPaths = useMemo(() => getAllStates(blocks).concat(themes.map(el => el.value)), [blocks, themes]);

  const parentPath = screenCreationMenu.parentStatePath || screenCreationMenu.parentThemeValue;
  const supposedNewStatePath = joinPaths('NewState', parentPath);

  const [screenName, setScreenName] = useState(() =>
    getLastPartOfPath(getNewNameWithIndex(supposedNewStatePath, busyPaths))
  );
  const [showScreenNameValue, setShowScreenNameValue] = useState(screenName);
  const touched = useRef(false);
  const debouncedScreenName = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!screenCreationMenu.open) return;

    const keyboardServiceFromRef = keyboardService.current;
    keyboardServiceFromRef.bind('esc', () => {
      newConnectorSubject$.next(initialNewConnectorState);
      dispatch(closeScreenCreationMenu());
    });
    return () => keyboardServiceFromRef.unbindAll();
  }, [dispatch, screenCreationMenu.open]);

  const { errorText, isInvalid, triggerValidation, reset } = useNewStateNameValidation();

  const setStateName = useCallback(
    async value => {
      const stateValue = value.replace(/\/+/g, '/') as string;
      setScreenName(stateValue);
      triggerValidation(stateValue, parentPath);
      touched.current = true;
    },
    [parentPath, triggerValidation]
  );

  useEffect(() => {
    if (!screenCreationMenu.open) {
      const name = getLastPartOfPath(getNewNameWithIndex(supposedNewStatePath, busyPaths));
      setScreenName(name);
      touched.current = false;
      setOpenParentSelection(false);
      reset();
    }
  }, [busyPaths, screenCreationMenu.open, parentPath, reset, supposedNewStatePath]);

  useEffect(() => {
    if (touched.current) {
      setScreenName(prevScreenName => {
        const name = getLastPartOfPath(getNewNameWithIndex(joinPaths(prevScreenName, parentPath), busyPaths));
        const pureName = name.replace(parentPath ? `${parentPath}/` : '', '');
        triggerValidation(pureName, parentPath);
        return pureName;
      });
    } else {
      const name = getLastPartOfPath(getNewNameWithIndex(supposedNewStatePath, busyPaths));
      setScreenName(name);
      triggerValidation(name, parentPath);
    }
  }, [busyPaths, parentPath, triggerValidation, supposedNewStatePath]);

  useEffect(() => {
    debouncedScreenName.current && clearTimeout(debouncedScreenName.current);
    debouncedScreenName.current = setTimeout(() => {
      setShowScreenNameValue(screenName);
    }, 60);
    let debouncedScreenNameRef = debouncedScreenName.current;
    return () => {
      clearTimeout(debouncedScreenNameRef);
    };
  }, [screenName]);

  const createScreen = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (screenCreationMenu.from && screenCreationMenu.toPath && screenCreationMenu.toPath !== './') {
        setOpen(true);
        return;
      }
      if (screenName) {
        const screenPath = transformStateNameToPath(screenName.trim());
        await dispatch(
          addNewStateWithSave({
            screenPath,
            setEdit: true,
            parentStatePath: screenCreationMenu.parentStatePath,
            theme: screenCreationMenu.parentThemeValue,
          })
        );
        if (screenCreationMenu.from) {
          await dispatch(
            makeNewConnectorAsync({
              from: screenCreationMenu.from,
              to: getValidKonvaName(`${parentPath}${screenPath}`),
            })
          );
          newConnectorSubject$.next(initialNewConnectorState);
        }
      }
    },
    [
      screenCreationMenu.from,
      screenCreationMenu.toPath,
      screenCreationMenu.parentStatePath,
      screenCreationMenu.parentThemeValue,
      screenName,
      dispatch,
      parentPath,
    ]
  );

  const onSelectReaction = useCallback(
    async (value: TReactionsTagNames) => {
      if (isInvalid) {
        return;
      }
      if (screenCreationMenu.from && screenCreationMenu.toPath) {
        storeSelectedValue.current = value;
        setOpen(true);
        return;
      }
      if (screenName) {
        const screenPath = transformStateNameToPath(screenName.trim());
        await dispatch(
          addNewStateWithSave({
            screenPath,
            addingBlock: value,
            setEdit: true,
            theme: screenCreationMenu.parentThemeValue,
            parentStatePath: screenCreationMenu.parentStatePath,
          })
        );
        if (screenCreationMenu.from) {
          await dispatch(
            makeNewConnectorAsync({
              from: screenCreationMenu.from,
              to: getValidKonvaName(`${parentPath}${screenPath}`),
            })
          );
          newConnectorSubject$.next(initialNewConnectorState);
        }
      }
    },
    [
      isInvalid,
      screenCreationMenu.from,
      screenCreationMenu.toPath,
      screenCreationMenu.parentStatePath,
      screenCreationMenu.parentThemeValue,
      screenName,
      dispatch,
      parentPath,
    ]
  );

  const saveAfterConfirm = useCallback(async () => {
    if (screenName && screenCreationMenu.from) {
      const screenPath = transformStateNameToPath(screenName.trim());
      await dispatch(
        addNewStateWithSave({
          screenPath,
          addingBlock: storeSelectedValue.current,
          setEdit: true,
          parentStatePath: screenCreationMenu.parentStatePath,
          theme: screenCreationMenu.parentThemeValue,
        })
      );
      storeSelectedValue.current = undefined;
      await dispatch(
        makeNewConnectorAsync({
          from: screenCreationMenu.from,
          to: getValidKonvaName(`${parentPath}${screenPath}`),
        })
      );
      newConnectorSubject$.next(initialNewConnectorState);
    }
  }, [
    screenName,
    screenCreationMenu.from,
    screenCreationMenu.parentStatePath,
    screenCreationMenu.parentThemeValue,
    dispatch,
    parentPath,
  ]);

  const cancelAdding = useCallback(() => {
    setOpen(false);
    dispatch(closeScreenCreationMenu());
    // @ts-ignore
    newConnectorSubject$.next(initialNewConnectorState);
  }, [dispatch]);

  const onChangeParentSelect = useCallback(
    (value: (string | number)[] | null) => {
      if (value && value[0]) {
        dispatch(
          openScreenCreationMenu({
            ...screenCreationMenu,
            parentStatePath: value[0] as string,
          })
        );
      }
    },
    [dispatch, screenCreationMenu]
  );

  const optionsToSelect = useMemo(() => {
    if (screenCreationMenu.parentStatePath) {
      const screenGroup = findScreenByPath(screenCreationMenu.parentStatePath, blocks);
      if (screenGroup && screenGroup.states) {
        return screenGroup.states.map(screen => ({
          value: screen.path,
          label: hideRootSlashInPath(screen.path),
        }));
      }
    }
    if (screenCreationMenu.parentThemeValue) {
      return blocks
        .filter(state => state.theme === screenCreationMenu.parentThemeValue)
        .map(screen => ({
          value: screen.path,
          label: hideRootSlashInPath(screen.path),
        }));
    }
    return blocks.map(screen => ({
      value: screen.path,
      label: hideRootSlashInPath(screen.path),
    }));
  }, [screenCreationMenu.parentStatePath, screenCreationMenu.parentThemeValue, blocks]);

  const toggleParentSelection = useCallback(() => {
    setOpenParentSelection(prevShown => {
      if (prevShown) {
        dispatch(
          openScreenCreationMenu({
            ...screenCreationMenu,
            parentStatePath: '',
          })
        );
      }
      return !prevShown;
    });
  }, [dispatch, screenCreationMenu]);

  useFloaterPosition({
    enable: screenCreationMenu.open,
    floaterElement: menuWrapper,
    target: useMemo(
      () => ({
        width: 0,
        height: 0,
        x: screenCreationMenu.pointerPosition.x,
        y: screenCreationMenu.pointerPosition.y,
      }),
      [screenCreationMenu.pointerPosition]
    ),
    options: floaterOptions,
  });

  if (!screenCreationMenu.open) return null;

  return (
    <>
      <form onSubmit={createScreen}>
        <ClickAwayListener handleClickOut={cancelAdding} delay={100}>
          <div ref={menuWrapper} className={styles.CreationScreenMenu}>
            <div className={styles.header}>
              <div className={styles.title}>{t('CreationScreenMenu:title')}</div>
              <div>
                <InputText
                  data-test-id='ScreenCreationMenu:screenName'
                  value={screenName}
                  invalid={isInvalid}
                  errorText={errorText}
                  onChange={setStateName}
                  hint={t('CreationScreenMenu:title:hint')}
                  autoFocus
                />
              </div>
            </div>

            {!screenCreationMenu.fixedParentPath && (
              <div className={styles.parentSelection}>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className={styles.title}>{t('CreationScreenMenu:parentSelectionTitle')}</div>
                  <div className='d-flex'>
                    <SwitchButton
                      id='switch-parentSelection'
                      onChange={toggleParentSelection}
                      size='md'
                      value={showParentSelection}
                    />
                  </div>
                </div>
                {showParentSelection && (
                  <div className='mt-3'>
                    <JustSelect
                      inputPlaceholder={t('CreationScreenMenu:parentSelectionPlaceholder')}
                      fullWidth
                      options={optionsToSelect}
                      value={parentPath}
                      onChange={onChangeParentSelect}
                    />
                  </div>
                )}
              </div>
            )}
            {parentPath && (
              <div className={styles.inGroupInfo}>
                <div className={styles.inGroupInfoTitle}>{t('CreationScreenMenu:inGroupInfoTitle')}</div>
                <div className={styles.inGroupInfoBody}>
                  {[hideRootSlashInPath(parentPath), showScreenNameValue].join('/').replace(/\/+/g, '/')}
                </div>
              </div>
            )}
            <div className={styles.body}>
              <div className={styles.item} data-test-id='CreationScreenMenu:addBlock'>
                <div className={styles.itemTitle}>{t('CreationScreenMenu:addBlock')}</div>
                <Icon name='farChevronRight' />
                <AddingSimpleBlock type='reactions' onSelectReaction={onSelectReaction} />
              </div>
              <div className={styles.item} data-test-id='CreationScreenMenu:selectAction'>
                <div className={styles.itemTitle}>{t('CreationScreenMenu:readyBlock')}</div>
                <Icon name='farChevronRight' />
                <AddingSimpleBlock type='meta' onSelectReaction={onSelectReaction} />
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                color='primary'
                type='submit'
                disabled={screenName.trim().length === 0 || isInvalid}
                data-test-id='CreationScreenMenu:CreateState:submit'
              >
                {t('CreationScreenMenu:CreateState')}
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </form>
      <Modal
        isOpen={openConfirmModal}
        title={t(`CreationScreenMenu:delete_exist_connection_title`)}
        buttonSubmitColor='danger'
        buttonSubmitText={t(`CreationScreenMenu:delete_exist_connection_submit`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        onCancelClick={cancelAdding}
        onActionClick={saveAfterConfirm}
      >
        <p>
          {t(`CreationScreenMenu:delete_exist_connection_text`, screenCreationMenu.fromPath, screenCreationMenu.toPath)}
        </p>
      </Modal>
    </>
  );
};
