import React from 'react';
import { Banner } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import { t } from 'localization';

import { useIsStickersWillBeDeletedWithState } from 'modules/JGraph/hooks/useStickersByPath';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

interface DeleteStateModalInterface {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  screen?: JStateWithId;
}
const DeleteStateModal = function ({ isOpen, onCancel, onSubmit, screen }: DeleteStateModalInterface) {
  const { isStickersWillBeDeletedWithState, parentScreen } = useIsStickersWillBeDeletedWithState(screen);

  return (
    <Modal
      isOpen={isOpen}
      data-test-id='RightSideMenu:openDelete:modal'
      title={t(`RightSideMenu:delete_state_title`)}
      buttonSubmitColor='danger'
      buttonSubmitText={t(`RightSideMenu:delete_state_submit`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Cancel')}
      onCancelClick={onCancel}
      onActionClick={onSubmit}
      buttonSubmitTestId='RightSideMenu:modal:submitDelete'
      buttonCancelTestId='RightSideMenu:modal:cancelDelete'
    >
      <p>{t(`RightSideMenu:delete_state_text`)}</p>
      {isStickersWillBeDeletedWithState && (
        <Banner
          type='warning'
          withIcon
          BannerText={() => (
            <span style={{ whiteSpace: 'break-spaces' }}>
              {t(`RightSideMenu:DeleteState:StickersLostWarning`, {
                levelPath: hideRootSlashInPath(parentScreen?.path ?? ''),
              })}
            </span>
          )}
        />
      )}
    </Modal>
  );
};

export default React.memo(DeleteStateModal);
