import React, { PureComponent } from 'react';
import { Icon, shortPulling } from '@just-ai/just-ui';
import Modal from 'components/Modal';
import { AppLogger } from '@just-ai/logger';
import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';

export interface Alignment {
  sourceIndex: number;
  sourceLength: number;
  exampleIndex: number;
  exampleLength: number;
  type: string;
  weight: number;
  inSeqNum: number;

  entityAlignment: any;
  debugData: any;
}

export interface WordInfoData {
  word: string;
  lemma: string;
  pos: string;
  weight: string;
  punctuation: boolean;
  pattern: boolean;
}

export interface AlignmentWeights {
  inputTotalWeight: number;
  exampleTotalWeight: number;
  inputAlignedWeight: number;
  exampleAlignedWeight: number;
  score: number;
}

type AlignmentProps = {
  phrase1: Array<WordInfoData>;
  phrase2: Array<WordInfoData>;
  weights: AlignmentWeights;
  alignment: Array<Alignment>;

  isOpen: boolean;
  toggle: () => void;
};

interface AlignmentState {
  // no state
}

type WordInfoProps = {
  index: number;
  word: WordInfoData;
  alignment?: Alignment;
};

class WordInfoState {
  isOpened: boolean = false;
  isRightPosition: boolean = false;
}

export class WordInfo extends PureComponent<WordInfoProps, WordInfoState> {
  state = new WordInfoState();

  private popup: React.RefObject<HTMLDivElement> = React.createRef();

  checkClickTarget = (e: MouseEvent): void => {
    if (this.popup && this.popup.current && e.target && !this.popup.current.contains(e.target as Node)) {
      this.closePopup();
    }
  };

  closePopup = () => {
    document.removeEventListener('click', this.checkClickTarget);
    this.setState({
      isOpened: false,
    });
  };

  toggle = () => {
    const modalElement = document.getElementsByClassName('modal fade')[0] as HTMLDivElement;
    if (this.state.isOpened && modalElement) {
      modalElement.removeEventListener('click', this.checkClickTarget);
    } else {
      modalElement.addEventListener('click', this.checkClickTarget);
    }
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  componentDidUpdate(prevProps: Readonly<WordInfoProps>, prevState: Readonly<WordInfoState>): void {
    if (
      !prevState.isOpened &&
      prevState.isOpened !== this.state.isOpened &&
      this.popup.current &&
      this.popup.current.getBoundingClientRect().right > window.innerWidth
    ) {
      this.setState({ isRightPosition: true });
    }
  }

  stopPropagation = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    return (
      <div className='word' onClick={this.toggle}>
        {this.props.word.word}
        <span style={{ cursor: 'pointer', paddingLeft: '7px' }}>
          <Icon name='faCaretDown' size='sm' />
        </span>
        <div
          ref={this.popup}
          style={{ right: this.state.isRightPosition ? 0 : 'unset' }}
          className={`modal-content popup ${!!this.props.alignment ? 'full' : ''}`}
          onClick={this.stopPropagation}
          hidden={!this.state.isOpened}
        >
          <div className='alignment-table'>
            <div className='name'>norm:</div>
            <div className='value'>{this.props.word.word}</div>
          </div>
          <div className='alignment-table'>
            <div className='name'>lemma:</div>
            <div className='value'>{this.props.word.lemma}</div>
          </div>
          <div className='alignment-table'>
            <div className='name'>weight:</div>
            <div className='value'>{this.props.word.weight}</div>
          </div>
          <div className='alignment-table'>
            <div className='name'>POS:</div>
            <div className='value'>{this.props.word.pos}</div>
          </div>
          <div className='alignment-table'>
            <div className='name'>punct:</div>
            <div className='value'>{this.props.word.punctuation}</div>
          </div>
          <div className='alignment-table'>
            <div className='name'>pattern:</div>
            <div className='value'>{this.props.word.pattern}</div>
          </div>
          {!!this.props.alignment ? (
            <div>
              <hr />
              <div className='alignment-table'>
                <div className='name'>type:</div>
                <div className='value'>{this.props.alignment.type}</div>
              </div>
              <div className='alignment-table'>
                <div className='name'>inSeqNum:</div>
                <div className='value'>{this.props.alignment.inSeqNum}</div>
              </div>
              <div className='alignment-table'>
                <div className='name'>input aligned weight:</div>
                <div className='value'>{this.props.alignment.weight}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

class AlignmentDialog extends PureComponent<AlignmentProps, AlignmentState> {
  private alignmentPane: React.RefObject<HTMLDivElement> = React.createRef();
  private alignmentCanvas: React.RefObject<HTMLCanvasElement> = React.createRef();
  translation = i18nTranslation('AlignmentDialog');

  render() {
    const { t } = this.translation;
    return (
      <Modal
        isOpen={this.props.isOpen}
        onCancelClick={this.props.toggle}
        buttonCancelTestId='Widget.Alignment.CloseButton'
        buttonCancelText={t('closeAlignmentButton')}
        title={t('alignmentTitle')}
        size='lg'
      >
        <div className='alignment-container' style={{ paddingTop: '16px' }}>
          <div ref={this.alignmentPane} className='alignment-pane'>
            <div className='sentence1'>
              <div className='words'>
                {this.props.phrase1.map((w, index) => (
                  <WordInfo word={w} index={index} key={index} />
                ))}
              </div>
              <div className='percentage'>
                {Math.round(this.props.weights.inputAlignedWeight * 100) / 100} /{' '}
                {Math.round(this.props.weights.inputTotalWeight * 100) / 100}
              </div>
            </div>

            <div className='sentence2'>
              <div className='words'>
                {this.props.phrase2.map((w, index) => (
                  <WordInfo word={w} index={index} key={index} alignment={this.findAlignForWord(index)} />
                ))}
              </div>
              <div className='percentage'>
                {Math.round(this.props.weights.exampleAlignedWeight * 100) / 100} /{' '}
                {Math.round(this.props.weights.exampleTotalWeight * 100) / 100}
              </div>
            </div>
          </div>
          <canvas ref={this.alignmentCanvas} className='alignment-canvas' />
        </div>
      </Modal>
    );
  }

  findAlignForWord(index: number): Alignment | undefined {
    return this.props.alignment.find(a => index >= a.exampleIndex && index < a.exampleIndex + a.exampleLength);
  }

  componentDidMount() {
    (async () => {
      const result = await shortPulling(
        () => {
          if (!this.alignmentCanvas.current || !this.alignmentPane.current) return;
          return [this.alignmentCanvas.current, this.alignmentPane.current] as const;
        },
        { delay: 100, timeout: 1000 }
      ).catch(AppLogger.createErrorHandler('AlignmentDialog:customPulling'));
      if (!result) return;

      const [ac, ad] = result;
      const w1 = ad.querySelectorAll('.sentence1 .word');
      const w2 = ad.querySelectorAll('.sentence2 .word');
      const sentence1 = ad.querySelector('.sentence1');
      const sentence2 = ad.querySelector('.sentence2');
      const maxWidth =
        sentence1 && sentence2 && sentence1.clientWidth > sentence2.clientWidth
          ? sentence1?.clientWidth
          : sentence2?.clientWidth;
      ac.width = maxWidth ? maxWidth : ad.clientWidth;
      ac.height = ad.clientHeight;

      this.showAlignment(ac, w1, w2, this.props.alignment);
    })();
  }

  private showAlignment(
    canvas: HTMLCanvasElement,
    words1: NodeListOf<Element>,
    words2: NodeListOf<Element>,
    alignment: Array<Alignment>
  ) {
    const canvasContext = canvas.getContext('2d')!!;
    canvasContext.lineWidth = 3;

    function rect(words: NodeListOf<Element>, i: number) {
      const cr = canvas.getBoundingClientRect();
      const wr = words.item(i).getBoundingClientRect();
      return { x: wr.left - cr.left, y: wr.top - cr.top, w: wr.width, h: wr.height };
    }

    alignment.forEach(a => {
      const capturedWordsIndexes: number[] = [];
      for (let index = 0; index < a.sourceLength; index++) {
        capturedWordsIndexes.push(a.sourceIndex + index);
      }
      capturedWordsIndexes.forEach((wordIndex: number) => {
        canvasContext.beginPath();
        const w1 = rect(words1, wordIndex);
        const w2 = rect(words2, a.exampleIndex);
        canvasContext.moveTo(w1.x + w1.w / 2, w1.y + 48);
        canvasContext.lineTo(w2.x + w2.w / 2, w2.y + 12);
        canvasContext.stroke();
      });
    });
  }
}

export default AlignmentDialog;
