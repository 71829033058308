import React, { useCallback, useMemo, useState } from 'react';
import { Banner, JustSelect, Label, Spinner } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import { t } from 'localization';
import { useAppDispatch } from 'storeHooks';

import { JGraphTheme, JStateWithId } from 'reducers/JGraph.reducer/types';
import { filterStatesRecursively } from 'reducers/JGraph.reducer/Graph';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { getParentPaths } from 'reducers/JGraph.reducer/utils';
import { moveStateInGroup } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { getThemeName } from 'modules/JGraph/utils/themesUtils';
import { useIsStickersWillBeDeletedWithState } from 'modules/JGraph/hooks/useStickersByPath';

interface RenameStateModalInterface {
  isOpen: boolean;
  screen: JStateWithId;
  screens: JStateWithId[];
  themes: JGraphTheme[];
  onClose: () => void;
}
const ChangeGroupModal = function ({ isOpen, onClose, screen, screens, themes }: RenameStateModalInterface) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch();

  const { isStickersWillBeDeletedWithState, parentScreen } = useIsStickersWillBeDeletedWithState(screen);

  const allOptions = useMemo(() => {
    const allStates = filterStatesRecursively(
      screens,
      state => !state.path.startsWith(screen.path + '/') || state.theme !== screen.theme
    ).map(state => ({
      label: hideRootSlashInPath(state.path),
      value: state.path,
    }));
    const themesOptions = themes.map(theme => ({
      label: getThemeName(theme.value),
      value: theme.value,
    }));
    return [...themesOptions, ...allStates].filter(stateOption => stateOption.value !== screen.path);
  }, [screen.path, screen.theme, screens, themes]);

  const parentStatePath = useMemo(() => {
    const { parentStatePath } = getParentPaths(screen.path, screen.theme);
    return parentStatePath;
  }, [screen.path, screen.theme]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    await dispatch(moveStateInGroup({ what: screen.path, where: value }));
    setLoading(false);
    onClose();
  }, [dispatch, onClose, screen.path, value]);

  const onChange = useCallback((value: (React.ReactText | null)[] | null) => {
    if (value && value[0]) {
      setValue(String(value[0]));
    }
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <Modal
        isOpen={isOpen}
        title={t(`ChangeGroupModal:Modal:Title`)}
        buttonSubmitColor='primary'
        buttonSubmitText={t(`ChangeGroupModal:Modal:SubmitTitle`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        buttonSubmitDisabled={value === '' || value === parentStatePath || loading}
        buttonCancelDisabled={loading}
        onCancelClick={onClose}
        onActionClick={onSubmit}
        disableActionButtonAutoFocus
      >
        {loading && <Spinner size='sm' />}
        <Label>{t('ChangeGroupModal:Modal:Label')}</Label>
        <JustSelect
          fullWidth
          position='fixed'
          options={allOptions}
          onChange={onChange}
          value={value || parentStatePath}
          className='margin-bottom-2x'
        />
        {isStickersWillBeDeletedWithState && (
          <Banner
            type='warning'
            withIcon
            BannerText={() => (
              <span style={{ whiteSpace: 'break-spaces' }}>
                {t(`RightSideMenu:MoveLevelState:StickersLostWarning`, {
                  levelPath: hideRootSlashInPath(parentScreen?.path ?? ''),
                })}
              </span>
            )}
          />
        )}
      </Modal>
    </>
  );
};

export default React.memo(ChangeGroupModal);
