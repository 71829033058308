import React, { useCallback, Fragment, useMemo } from 'react';
import Konva from 'konva';
import { usePromisifyComponent } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import { t } from 'localization';

import { useAppSelector } from 'storeHooks';

import { createPng } from 'modules/JGraph/utils/stageUtils';
import { JGraphProviderSubject$ } from 'modules/JGraph/contexts/JGraphContext';
import SearchState from 'modules/JGraph/view/SearchState.feature';
import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';

import { useStickersByPath } from './useStickersByPath';

export function useStageActions(stageRef: React.MutableRefObject<Konva.Stage | null>, selectedGroupPath?: string) {
  const { themes, projectShortName, screens } = useAppSelector(state => ({
    themes: state.JGraphReducer.graph.themes,
    projectShortName: state.CurrentProjectsReducer.currentProject,
    screens: state.JGraphReducer.graph.blocks,
  }));

  const screensOnStage = useMemo(() => {
    return selectedGroupPath ? findScreenByPath(selectedGroupPath, screens)?.states ?? [] : screens;
  }, [screens, selectedGroupPath]);

  const openSearch = useCallback(() => SearchState.ToggleGlobalSearch$.next(), []);

  const [exportPngModalNode, showExportPngModal] = usePromisifyComponent((resolve, reject, opened) => (
    <Modal
      isOpen={opened}
      title={t('ModalCreatePng:title')}
      buttonCancelText={t('Cancel')}
      buttonSubmitText={t('ModalCreatePng:actionButtonText')}
      onActionClick={resolve}
      onCancelClick={reject}
    >
      {t('ModalCreatePng:text')}
    </Modal>
  ));

  const createThemesPagePng = useCallback(
    () =>
      showExportPngModal().then(() => {
        if (!stageRef.current) return;
        const { projectName } = JGraphProviderSubject$.getValue();
        const fileName = `${projectName}-themes`;
        return createPng(fileName, projectShortName, stageRef.current, themes, []);
      }),
    [stageRef, projectShortName, themes, showExportPngModal]
  );

  const stickersOnStage = useStickersByPath(selectedGroupPath);
  const createStatesPagePng = useCallback(() => {
    return showExportPngModal().then(() => {
      if (!stageRef.current) return;
      const { projectName } = JGraphProviderSubject$.getValue();
      const fileName = `${projectName}${selectedGroupPath ? '-' + selectedGroupPath : ''}`;
      return createPng(fileName, projectShortName, stageRef.current, screensOnStage, stickersOnStage);
    });
  }, [selectedGroupPath, projectShortName, screensOnStage, showExportPngModal, stageRef, stickersOnStage]);

  return {
    actions: {
      openSearch,
      createThemesPagePng,
      createStatesPagePng,
    },
    nodes: [exportPngModalNode].map((el, index) => <Fragment key={index}>{el}</Fragment>),
  };
}
