import React, { useEffect, useCallback } from 'react';
import { useToggle, usePromiseProcessing } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import { t } from 'localization';

import { FeaturesNotificationServiceInstance, JGraphNotificationFeature } from 'services/FeaturesNotificationService';

export function useSkipGuideTour() {
  const [shouldShowGuideTourState, callShouldShowGuideTour] = usePromiseProcessing(() =>
    FeaturesNotificationServiceInstance.shouldNotifyAbout(JGraphNotificationFeature.GuideTour)
  );
  const isShouldShowGuideTourState = shouldShowGuideTourState.result;
  const [guideTourStatusState, getGuideTourStatus] = usePromiseProcessing(() =>
    FeaturesNotificationServiceInstance.getStatus(JGraphNotificationFeature.GuideTour)
  );
  const guideTourStatus = guideTourStatusState.result;

  const [isShowSkipForeverModal, openSkipForeverModal, closeSkipForeverModal] = useToggle(false);

  useEffect(() => {
    getGuideTourStatus();
    callShouldShowGuideTour();
  }, [callShouldShowGuideTour, getGuideTourStatus]);

  const skip = useCallback(() => {
    if (!guideTourStatus || guideTourStatus === 'none') {
      FeaturesNotificationServiceInstance.skipNotification(JGraphNotificationFeature.GuideTour);
    }
    if (guideTourStatus === 'skippedOnce') {
      openSkipForeverModal();
    }
    return;
  }, [guideTourStatus, openSkipForeverModal]);

  const skipForever = useCallback(async () => {
    await FeaturesNotificationServiceInstance.skipForeverNotification(JGraphNotificationFeature.GuideTour);
    await getGuideTourStatus();
    closeSkipForeverModal();
  }, [closeSkipForeverModal, getGuideTourStatus]);

  return {
    isShouldShowGuideTourState,
    skip,
    skipForever,
    isShowSkipForeverModal,
    closeSkipForeverModal,
  };
}

interface ISkipGuideTourForeverModal {
  isModalOpen: boolean;
  onAgree: () => void;
  onDisagree: () => void;
}
export const SkipGuideTourForeverModal = ({ isModalOpen, onAgree, onDisagree }: ISkipGuideTourForeverModal) => (
  <Modal
    isOpen={isModalOpen}
    title={t('JGraphGuideTour:SkipForeverModal:Title')}
    buttonSubmitText={t('JGraphGuideTour:SkipForeverModal:SubmitText')}
    onActionClick={onAgree}
    buttonSubmitColor='primary'
    buttonCancelText={t('JGraphGuideTour:SkipForeverModal:CancelText')}
    onCancelClick={onDisagree}
    buttonCancelColor='secondary'
    buttonCancelOutline
  />
);
