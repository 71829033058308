import React, { useEffect, FC, useCallback } from 'react';
import { usePromisifyComponent } from '@just-ai/just-ui';
import Modal from 'components/Modal';

import localization, { t } from 'localization';
import { amplitudeInstance } from 'pipes/functions';

import step1 from 'img/jgraph/themesInformer/step1.png';
import step2 from 'img/jgraph/themesInformer/step2.png';
import step3 from 'img/jgraph/themesInformer/step3.png';
import step1en from 'img/jgraph/themesInformer/step1-en.png';
import step2en from 'img/jgraph/themesInformer/step2-en.png';
import step3en from 'img/jgraph/themesInformer/step3-en.png';

import { useAppContext } from 'modules/Caila/components/AppContext';
import { FeaturesNotificationServiceInstance, JGraphNotificationFeature } from 'services/FeaturesNotificationService';
import { ThemesInformerModalLocalization } from './localization/ThemesInformerModal.loc';
import { useStepper } from './components/StepperControl/hook';
import StepperControl from './components/StepperControl';

import styles from './styles.module.scss';

function useInformerModalImages() {
  const { language } = useAppContext();
  if (language === 'ru') return [step1, step2, step3];
  return [step1en, step2en, step3en];
}

localization.addTranslations(ThemesInformerModalLocalization);

const InformerStep1 = () => {
  const images = useInformerModalImages();
  return (
    <div className={styles.stepWrapper}>
      <img src={images[0]} loading='eager' alt='themes in jgraph' />
      <div className={styles.stepWrapper__content}>
        <h3>{t('ThemesInformerModal:Step:1:Title')}</h3>
        <div dangerouslySetInnerHTML={{ __html: t('ThemesInformerModal:Step:1:Description') }} />
      </div>
    </div>
  );
};
const InformerStep2 = () => {
  const images = useInformerModalImages();
  return (
    <div className={styles.stepWrapper}>
      <img src={images[1]} loading='eager' alt='themes in jgraph' />
      <div className={styles.stepWrapper__content}>
        <h3>{t('ThemesInformerModal:Step:2:Title')}</h3>
        <div dangerouslySetInnerHTML={{ __html: t('ThemesInformerModal:Step:2:Description') }} />
      </div>
    </div>
  );
};
const InformerStep3 = () => {
  const images = useInformerModalImages();
  return (
    <div className={styles.stepWrapper}>
      <img src={images[2]} loading='eager' alt='themes in jgraph' />
      <div className={styles.stepWrapper__content}>
        <h3>{t('ThemesInformerModal:Step:3:Title')}</h3>
        <div dangerouslySetInnerHTML={{ __html: t('ThemesInformerModal:Step:3:Description') }} />
      </div>
    </div>
  );
};

const informerSteps: FC[] = [InformerStep1, InformerStep2, InformerStep3];

type InformerProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};
const Informer = ({ isOpen, onSubmit, onCancel }: InformerProps) => {
  const { isLastStep, nextStep, setStepIndex, stepIndex, stepsCount } = useStepper(informerSteps);
  const onSubmitInner = useCallback(() => {
    if (isLastStep) {
      onSubmit();
    } else {
      nextStep();
    }
  }, [isLastStep, nextStep, onSubmit]);

  useEffect(() => {
    if (isOpen) amplitudeInstance.logEvent('J-Graph Theme Tutorial started');
  }, [isOpen]);
  useEffect(() => {
    if (isLastStep) amplitudeInstance.logEvent('J-Graph Theme Tutorial finished');
  }, [isLastStep]);

  const onClose = useCallback(() => {
    if (!isLastStep) {
      amplitudeInstance.logEvent('J-Graph Theme Tutorial aborted', { 'Theme Tutorial Step': stepIndex + 1 });
    }
    onCancel();
  }, [onCancel, stepIndex, isLastStep]);

  const Step = informerSteps[stepIndex] ?? null;
  return (
    <Modal
      isOpen={isOpen}
      centered
      className={styles.ThemesInformerModal}
      title={t('ThemesInformerModal:Title')}
      data-test-id='ThemesInformerModal'
      buttonSubmitText={isLastStep ? t('ThemesInformerModal:Done') : t('ThemesInformerModal:Next')}
      buttonSubmitColor='primary'
      onClickCloseIcon={onClose}
      onActionClick={onSubmitInner}
    >
      <Step />
      <StepperControl className={styles.stepper} onSelect={setStepIndex} index={stepIndex} count={stepsCount} />
    </Modal>
  );
};

const ThemesInformerModal = () => {
  const [themesInformerModalModalNode, showThemesInformerModalModal] = usePromisifyComponent(
    (resolve, reject, opened) => <Informer isOpen={opened} onCancel={reject} onSubmit={resolve} />,
    undefined,
    []
  );

  useEffect(() => {
    FeaturesNotificationServiceInstance.shouldNotifyAbout(JGraphNotificationFeature.ThemesInformerModal).then(
      isNeedToShow => {
        if (!isNeedToShow) return;
        return showThemesInformerModalModal()
          .then(() =>
            FeaturesNotificationServiceInstance.finishNotification(JGraphNotificationFeature.ThemesInformerModal)
          )
          .catch(() => {});
      }
    );
  }, [showThemesInformerModalModal]);

  return <div>{themesInformerModalModalNode}</div>;
};

ThemesInformerModal.displayName = 'ThemesInformerModal';

export default React.memo(ThemesInformerModal);
